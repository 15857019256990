<template>
  <div class="bulk-upload">
    <div class="d-flex align-center pt-5 pb-3">
      <router-link to="/products">
        <v-btn class="ml-3" icon><v-icon color="black">mdi-arrow-left</v-icon></v-btn>
      </router-link>
      <div class="page-title mono pl-3">{{$t('bulk_upload_title')}}</div>
      <v-spacer></v-spacer>
    </div>
    <div class="bulk-row">
      <v-stepper class="bulk-upload-ctn" :value="step">
        <v-stepper-header>
          <v-stepper-step step="1">{{ $t('bulk_upload_step_1') }} </v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step step="2">{{ $t('bulk_upload_step_2') }}</v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step step="3">{{ $t('bulk_upload_step_3') }}</v-stepper-step>
        </v-stepper-header>

        <v-stepper-items>
          <v-stepper-content step="1">
            <div class="row ma-2">
              <v-col class="infos font-weight-medium">
                <div class="title">{{ $t('bulk_upload_select_category') }}</div>
                <div class="hint">{{ $t('bulk_upload_select_category_hint') }}</div>
                <v-radio-group v-model="settings.selectedTemplate" row>
                  <v-radio v-for="template in templates" :key="template.key" :label="$t(`bulk_upload_${template.key}`)" :value="template"></v-radio>
                  <v-radio class="py-2" :label="$t('bulk_upload_template_copy')" value="copy"></v-radio>
                </v-radio-group>
                <v-autocomplete
                  v-if="settings.selectedTemplate === 'copy'"
                  solo
                  :placeholder="$t('bulk_upload_template_search_product')"
                  v-model="settings.templateProduct"
                  :items="filteredProducts"
                  :loading="isLoadingProduct"
                  no-filter
                  :search-input.sync="searchProduct"
                  return-object
                >
                  <template v-slot:selection="data">
                    <div v-bind="data.attrs">
                      {{data.item.name}} - {{data.item.sku}}
                    </div>
                  </template>
                  <template v-slot:item="data">
                    <div v-bind="data.attrs">
                      {{data.item.name}} - {{data.item.sku}}
                    </div>
                  </template>
                </v-autocomplete>

                <div v-if="settings.selectedTemplate !== 'copy'">
                  <div class="title">{{$t('bulk_upload_reference_language')}}</div>
                  <div class="my-2 hint">{{$t('bulk_upload_reference_language_hint')}}</div>
                  <v-autocomplete
                    class="mt-2"
                    solo
                    v-model="settings.defaultLanguage"
                    :items="languages"
                    item-value="key"
                    item-text="description"
                  >
                    <template v-slot:item="{ item, on, attrs }">
                      <v-list-item v-show="item.key != 'all'" v-on="on" v-bind="attrs">
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ item.description }}
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                  </v-autocomplete>

                  <div class="title">{{$t('bulk_upload_available_languages')}}</div>
                  <div class="my-2 hint">{{$t('bulk_upload_available_languages_hint')}}</div>
                  <v-autocomplete
                    class="mt-2"
                    solo
                    v-model="settings.availableLanguages"
                    :search-input.sync="searchLanguages"
                    multiple
                    :items="languages"
                    item-value="key"
                    item-text="description"
                  >
                    <template v-slot:item="{ item, on, attrs }">
                      <v-list-item v-on="on" v-bind="attrs">
                        <v-checkbox v-model="attrs.inputValue"></v-checkbox>
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ item.description }}
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                  </v-autocomplete>
                </div>

                <v-btn @click="downloadTemplate()" text color="primary"><v-icon class="mr-2">mdi-cloud-download</v-icon>{{ $t('bulk_upload_download_template')}}</v-btn>
                <div class="my-2 hint">{{$t('bulk_upload_download_template_hint')}}</div>
              </v-col>
              <v-col cols="5">
                <div class="file-upload-ctn ml-5">
                  <v-layout @click="clickOnFileInput($event, 'xls-file-input')" class="upload-btn" align-center justify-center column>
                    <div v-if="!file || !file.name" class="text-xs-center">
                      <v-icon x-large color="primary">mdi-cloud-upload</v-icon>
                      <div class="text-uppercase font-weight-bold">{{$t('bulk_upload_chose_file')}}</div>
                      <div class="format-hint">
                        {{$t('bulk_upload_supported_formats')}}:<br>
                        .xls,.xlsx
                      </div>
                    </div>

                    <div v-if="file && file.name" class="text-xs-center">
                      <v-icon x-large color="primary">mdi-cloud-upload</v-icon>
                      <div class="text-uppercase font-weight-bold">{{$t('csv_change_file')}}</div>
                      <div class="format-hint">
                        {{file.name}}
                      </div>
                    </div>
                  </v-layout>
                  <input v-show="false"  ref="xls-file-input" accept=".xls,.xlsx" @change="onFileChange($event)" type="file"/>
                  <div class="instructions-ctn py-2 px-5">
                    <div class="instructions-title font-weight-bold">{{$t('bulk_upload_how_to_use')}}</div>
                    <ul>
                      <li class="hint">{{ $t('bulk_upload_instructions_1') }}</li>
                      <li class="hint">{{ $t('bulk_upload_instructions_2') }}</li>
                      <li class="hint">{{ $t('bulk_upload_instructions_3') }}</li>
                      <li class="hint">{{ $t('bulk_upload_instructions_4') }}</li>
                      <li class="hint">{{ $t('bulk_upload_instructions_5') }}</li>
                      <li class="hint">{{ $t('bulk_upload_instructions_6') }}</li>
                    </ul>
                  </div>
                </div>
              </v-col>
            </div>
            <v-layout class="footer">
              <v-spacer grow></v-spacer>
              <v-flex shrink>
                <v-btn @click="readFile(file)" :disabled="!isFormValid" raised color="primary">next</v-btn>
              </v-flex>
            </v-layout>
          </v-stepper-content>
          <v-stepper-content step="2">
            <div class="row ma-2" v-if="isFileEmpty">
              {{ $t('bulk_upload_no_update_in_file') }}
            </div>
            <div v-if="!isFileEmpty && recap?.elabelToCreateCount <= totalInactiveCodesCount">
              <div class="row ma-0">
                <v-col cols="2" class="overview-title font-weight-bold py-2 px-0" shrink>{{$t('bulk_upload_overview')}}</v-col>
                <v-col grow>
                  <v-row class="ma-0">
                    <div class="label mr-2">{{ $t('bulk_upload_category') }}:</div>
                    <div v-if="settings.selectedTemplate.key" class="font-weight-bold">{{ $t(`bulk_upload_${settings.selectedTemplate.key}`) }}</div>
                    <div v-else class="font-weight-bold">{{ $t(`bulk_upload_${settings.templateElabel?.data?.published?.type}`) }}</div>
                  </v-row>
                  <v-row class="ma-0">
                    <div class="label mr-2">{{ $t('bulk_upload_input_language') }}:</div>
                    <div class="font-weight-bold"> {{ getLanguage(settings.defaultLanguage).description }}</div>
                  </v-row>
                  <v-row class="ma-0">
                    <div class="label mr-2">{{ $t('bulk_upload_published_languages') }}:</div>
                    <div class="font-weight-bold">
                      <span v-for="(lang, index) in settings.availableLanguages" :key="`available-lang-${lang}`">
                        <span class="cut-margin">{{getLanguage(lang).description}}</span>
                        <span class="comma" v-if="index < settings.availableLanguages.length - 1">, </span>
                      </span>
                    </div>
                  </v-row>
                </v-col>
              </div>
              <div class="delimiter"></div>
              <div class="row ma-0">
                <v-col cols="2" class="overview-title font-weight-bold px-0 py-6" shrink>{{$t('bulk_upload_file_summary')}}</v-col>
                <v-col grow>
                  <v-row class="ma-0 flex-nowrap" v-if="recap?.elabelToCreateCount">
                    <v-col cols="5" shrink class="file-summary-card new-elabel">
                      <div class="number font-weight-bold">
                        {{ $t("bulk_upload_new_elabel_count", {count: recap?.elabelToCreateCount }) }}
                      </div>
                      <div>{{ $t("bulk_upload_will_be_created") }}</div>
                    </v-col>
                    <v-col grow>
                      <v-switch class="mt-0" v-model="settings.assignCodeOnUpload" label="Assign a code to the new e-labels"></v-switch>
                      <div v-if="settings.assignCodeOnUpload">
                        <div>{{ $t('bulk_upload_create_batch_name') }}:</div>
                        <div>{{ $t('bulk_upload_batch_name_hint')}}</div>
                        <v-form v-model="batchFormValid" ref="batch-form">
                          <v-text-field class="mt-3 mb-6" :label="$t('bulk_upload_batch_name')" counter="50" :rules="batchRules" v-model="settings.uploadBatch"></v-text-field>
                        </v-form>
                      </div>
                    </v-col>
                  </v-row>
                  <div v-if="recap?.elabelToCreateCount" class="delimiter my-2"></div>
                  <v-row class="ma-0 flex-nowrap" v-if="recap?.elabelToUpdateCount">
                    <v-col cols="5" shrink class="file-summary-card update-elabel">
                      <div class="number font-weight-bold">
                        {{ $t("bulk_upload_update_elabel_count", {count: recap?.elabelToUpdateCount }) }}
                      </div>
                    </v-col>
                    <v-col grow>
                      <div>{{ $t("bulk_upload_update_elabel_hint")}}</div>
                    </v-col>
                  </v-row>
                  <div v-if="recap?.elabelToUpdateCount" class="delimiter my-2"></div>
                  <v-row class="ma-0 flex-nowrap" v-if="recap?.productsNotFound?.length">
                    <v-col cols="5" shrink class="file-summary-card not-found">
                      <div class="number font-weight-bold">
                        {{ $t("bulk_upload_not_found_elabel_count", {count: recap?.productsNotFound?.length }) }}
                      </div>
                      <div>{{ $t("bulk_upload_elabel_not_found") }}</div>
                    </v-col>
                    <v-col grow>
                      <div>{{$t("bulk_upload_elabel_not_found_description")}}</div>
                      <span v-show="showAllNotFound == true || index < 20" v-for="(product, index) in recap?.productsNotFound" :key="`product-not-found-${index}`">
                        <span class="cut-margin font-weight-bold">{{product}}</span>
                        <span class="comma" v-if="index < recap?.productsNotFound.length - 1">, </span>
                      </span>
                      <div v-if="!showAllNotFound && recap?.productsNotFound.length > 20" @click="showAllNotFound = true">{{$t('view_all')}}</div>
                      <div v-if="showAllNotFound" @click="showAllNotFound = false">{{$t('view_less')}}</div>
                    </v-col>
                  </v-row>
                </v-col>
              </div>
            </div>
            <div v-if="!isFileEmpty && recap?.elabelToCreateCount > totalInactiveCodesCount" class="content mb-5">
              <div class="important font-weight-bold" >{{ $t('error') }}:</div>
              <div class="infos mt-1 font-weight-medium" >{{ $t('bulk_upload_quota_reached', { count: recap?.elabelToCreateCount, availableCount: totalInactiveCodesCount })}}</div>
              <div class="infos font-weight-medium" >{{ $t('bulk_upload_quota_reached_contact_support') }}</div>
            </div>
            <v-layout class="footer">
              <v-flex grow>
                <v-btn @click="step = 1" text color="primary">Back</v-btn>
              </v-flex>
              <v-flex shrink>
                <v-btn @click="startUpload()" :disabled="recap?.elabelToCreateCount > totalInactiveCodesCount || isFileEmpty || (!recap?.elabelToCreateCount && !recap?.elabelToUpdateCount)" raised color="primary">next</v-btn>
              </v-flex>
            </v-layout>
          </v-stepper-content>
          <v-stepper-content class="custom-stepper" step="3">
            <div class="loading" >
              <div align-center justify-center row fill-height>
                <v-flex shrink class="text-center infos font-weight-bold">
                  <span v-if="upload.loading && !upload.completed">{{$t('uploading')}}({{uploadPercent}}%)</span><br>
                  <span v-if="upload.completed">{{$t('upload_complete')}}</span><br>
                  <div class="progress-ctn">
                    <v-progress-linear color="#15BBF0" rounded background-color="#D9D9D9" v-show="upload.loading" :value="uploadPercent"></v-progress-linear>
                  </div>
                  <div v-if="this.upload.throttling && upload.loading" class="hint mt-3">{{$t('bulk_upload_uploading_hint')}}</div>
                  <div v-if="upload.completed" class="upload-recap font-weight-light mt-3">
                    <span v-if="recap.elabelToCreateCount && settings.assignCodeOnUpload">{{$t('bulk_upload_recap_created', { count: recap.elabelToCreateCount, batchName: settings.uploadBatch})}}</span><br>
                    <span v-if="recap.elabelToCreateCount && !settings.assignCodeOnUpload">{{$t('bulk_upload_recap_created_no_qr', { count: recap.elabelToCreateCount, batchName: settings.uploadBatch})}}</span><br>
                    <span v-if="recap.elabelToUpdateCount">{{$t('bulk_upload_recap_updated', { count: recap.elabelToUpdateCount}) }}</span><br>
                  </div>
                </v-flex>
              </div>
            </div>
            <v-layout class="footer" row>
              <v-spacer v-if="this.upload.completed && !this.settings.uploadBatch"></v-spacer>
              <v-btn v-if="this.upload.completed" @click="goToProducts()" :text="this.settings.uploadBatch.length > 0" :raised="!this.settings.uploadBatch.length" color="primary">back to products</v-btn>
              <v-spacer v-if="this.upload.completed && !upload.errors.length && this.settings.uploadBatch"></v-spacer>
              <v-btn v-if="this.upload.completed && !upload.errors.length && this.settings.uploadBatch" @click="downloadBatch" raised color="primary">download this batch</v-btn>
            </v-layout>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </div>
  </div>
</template>

<script>

import { read, utils } from 'xlsx'
import Utils from '@/utils/utils'
import Api from '@/api'

import Languages from '@/const/languages'
import Countries from '@/const/countries'
import RemyWineTemplate from '@/const/templates/remycointreau_wine.json'
import RemySpiritTemplate from '@/const/templates/remycointreau_spirit.json'

import WineTemplate from '@/const/templates/wine.json'
import SpiritTemplate from '@/const/templates/spirit.json'
import AromatisedWineTemplate from '@/const/templates/aromatised_wine.json'
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'BulkUpload',
  computed: {
    ...mapGetters(['currentPlan', 'fullProductList', 'totalActiveCodesCount', 'inactiveCodes', 'totalInactiveCodesCount', 'selectedCampaign', 'products']),

    languages () {
      const languages = []
      languages.push({ key: 'all', description: this.$t('languages_all') })
      Languages.forEach((lang) => {
        languages.push({ key: lang.key, description: this.$t(`languages_${lang.key}`) })
      })
      return languages
    },

    templates () {
      return this.currentPlan && this.currentPlan.id && this.availableTemplates[this.currentPlan.id] ? this.availableTemplates[this.currentPlan.id] : this.availableTemplates.default
    },

    isFileEmpty () {
      return !this.recap?.elabelToCreateCount && !this.recap?.elabelToUpdateCount && this.recap.productsNotFound?.length === 0
    },

    isFormValid () {
      return this.file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' && (this.settings.selectedTemplate.key || (this.settings.selectedTemplate === 'copy' && this.settings.templateElabel?.id))
    },

    uploadPercent () {
      if (this.upload.step === 1) {
        return Math.round(Math.round(this.upload.elabelLoadedCount / this.elabelToLoad.length * 100) / 3)
      } else if (this.upload.step === 2) {
        return 33 + Math.round(Math.round(this.upload.eLabelUpdated / (this.recap.elabelToCreateCount + this.recap.elabelToUpdateCount) * 100) / 3)
      } else if (this.upload.step === 3) {
        return 66 + Math.round(Math.round(this.upload.eLabelUpdated / (this.recap.elabelToCreateCount + this.recap.elabelToUpdateCount) * 100) / 3)
      }
      return 0
    },

    filteredProducts () {
      const array = this.products.filter((p) => {
        return p.elabel && p.elabel.is_published
      })
      return array
    }
  },
  data () {
    return {
      showAllNotFound: false,
      isLoadingProduct: false,
      searchProduct: '',
      searchLanguages: '',
      settings: {
        selectedTemplate: {},
        defaultLanguage: 'en',
        availableLanguages: ['all'],
        assignCodeOnUpload: true,
        uploadBatch: '',
        templateProduct: {},
        templateElabel: {}
      },
      batchFormValid: true,
      batchRules: [
        v => v.length <= 50 || this.$t('bulk_upload_batch_name_length'),
        v => !!v || this.$t('bulk_upload_batch_name_required')
      ],
      recap: {},
      upload: {
        loading: false,
        eLabelUpdated: 0,
        codesAssigned: 0,
        elabelLoadedCount: 0,
        throttling: false,
        completed: false,
        errors: [],
        step: 1
      },
      step: 1,
      file: {},
      batchSize: 10,
      createCount: 0,
      productsNotFound: [],
      productInfoSheet: [],
      ingredientsSheet: [],
      nutritionSheet: [],
      pictogramsSheet: [],
      italianRecyclingSheet: [],
      freeTextSheet: [],
      countrySpecificSheet: [],
      elabelToLoad: [],
      elabelToUpdate: [],
      loadedElabel: {},
      productsToUpdate: {},
      availableTemplates: {
        'remycointreau-enterprise': [
          { key: 'wine', data: RemyWineTemplate },
          { key: 'spirit', data: RemySpiritTemplate }
        ],
        'remycointreau-enterprise-qr-manager': [
          { key: 'wine', data: RemyWineTemplate },
          { key: 'spirit', data: RemySpiritTemplate }
        ],
        default: [
          { key: 'wine', data: WineTemplate },
          { key: 'aromatised_wine', data: AromatisedWineTemplate },
          { key: 'spirit', data: SpiritTemplate }
        ]
      }
    }
  },
  watch: {
    async searchProduct (val) {
      this.isLoadingProduct = true
      await this.$store.dispatch('loadProducts', { search: val })
      this.isLoadingProduct = false
    },

    'settings.availableLanguages' (newVal, oldVal) {
      this.searchLanguages = ''
      const allIndex = newVal.indexOf('all')
      if (!newVal.length || (~allIndex && newVal.length > 1 && !~oldVal.indexOf('all'))) {
        this.settings.availableLanguages = ['all']
      } else if (~allIndex && newVal.length > 1) {
        this.settings.availableLanguages.splice(allIndex, 1)
      }
    },
    '$route.params' () {
      if (this.$route.name === 'bulk-upload') {
        this.resetState()
      }
    },

    async 'settings.templateProduct' (newVal, oldVal) {
      if (newVal?.id && newVal?.elabel?.id) {
        // load elabel data
        this.settings.templateElabel = await this.$store.dispatch('loadElabel', { sku: newVal.sku, id: newVal.id })
        this.settings.defaultLanguage = this.settings.templateElabel.data.published.default_language
      }
    }
  },
  methods: {
    ...mapMutations({
      setTotalActiveCount: 'SET_TOTAL_ACTIVE_CODES_COUNT',
      clearFilters: 'CLEAR_ACTIVE_CODE_FILTERS'
    }),

    getLanguage (lang) {
      return this.languages.find((l) => {
        return l.key === lang
      })
    },

    downloadTemplate () {
      window.open(Api.elabel_template_url, '_blank')
    },

    resetState () {
      console.log('resseting state', this.settings)
      this.step = 1
      this.settings.upload = {
        loading: false,
        eLabelUpdated: 0,
        codesAssigned: 0,
        elabelLoadedCount: 0,
        throttling: false,
        completed: false,
        errors: [],
        step: 1
      }
      this.file = {}
      this.$refs['xls-file-input'].value = ''
      this.recap = {}
      this.settings = {
        selectedTemplate: {},
        defaultLanguage: 'en',
        availableLanguages: ['all'],
        assignCodeOnUpload: true,
        uploadBatch: ''
      }

      this.productInfoSheet = []
      this.pictogramsSheet = []
      this.ingredientsSheet = []
      this.nutritionSheet = []
      this.italianRecyclingSheet = []
      this.freeTextSheet = []
      this.elabelToLoad = []
      this.elabelToUpdate = []
      this.loadedElabel = {}
      this.productsToUpdate = {}
    },

    downloadBatch () {
      const filter = {
        name: 'Upload Batch',
        key: 'scantrust_upload_batch',
        value: this.settings.uploadBatch,
        searchKey: 'scm_scantrust_upload_batch',
        searchValue: this.settings.uploadBatch
      }

      this.clearFilters()
      this.$store.dispatch('addCodeFilter', filter)
      this.$store.dispatch('loadCodes', { campaign: this.selectedCampaign.id })
      this.resetState()
      this.$router.push({ name: 'codes' })
    },

    goToProducts () {
      this.resetState()
      this.$nextTick(() => {
        this.$router.push({ name: 'products' })
      })
    },

    clickOnFileInput (event, inputName) {
      console.log('click?')
      if (event) {
        event.stopImmediatePropagation()
      }
      this.$refs[inputName].click()
    },

    async assignCodesBatch (index, isRetry) {
      const promiseUpdateCodesArray = []

      // Take next BatchSize Products
      let array = JSON.parse(JSON.stringify(this.elabelToUpdate.filter((item) => {
        return item.assignCode === true
      })))
      array = array.splice(index, this.batchSize)

      if (!array.length) {
        // this.completed = true
        this.upload.loading = false
        this.setTotalActiveCount(this.totalActiveCodesCount + this.createCount)
        return
      }

      if (this.inactiveCodes.length < array.length) {
        await this.$store.dispatch('loadInactiveCodes')
        if (!this.inactiveCodes.length) {
          this.completed = true
          this.quotaError = true
          this.uploadErrors.push('Maximum number of codes reached. Contact support to increase the number of codes available.')
          this.setTotalActiveCount(this.totalActiveCodesCount + this.createCount)
        }
      }
      array.forEach((item, promiseIndex) => {
        const tags = {}
        if (this.settings.uploadBatch) {
          tags.scantrust_upload_batch = this.settings.uploadBatch
        }

        if (!isRetry) {
          this.createCount++
        }
        promiseUpdateCodesArray.push(this.$store.dispatch('activateAndAssignCode', { product: item.product, tags, index: promiseIndex }))
      })

      return new Promise((resolve, reject) => {
        Promise.all(promiseUpdateCodesArray).then(async (res) => {
          this.upload.codesAssigned += promiseUpdateCodesArray.length
          resolve(this.assignCodesBatch(index + this.batchSize))
        }).catch((err) => {
          if (err && err.response && err.response.status === 429) {
            this.upload.throttling = true
            console.log('Throttling assign code')
            setTimeout(() => {
              resolve(this.assignCodesBatch(index, true))
            }, 10000)
          }
        })
      })
    },

    async loadElabels (index) {
      const batch = []
      let array = JSON.parse(JSON.stringify(this.elabelToLoad))
      array = array.splice(index, this.batchSize)
      if (!array.length) {
        this.upload.elabelLoadedCount = this.elabelToLoad.length
        console.log('loading done')
        console.log(this.loadedElabel)
        return
      }

      array.forEach((item) => {
        batch.push(this.$store.dispatch('loadElabel', { sku: item.product.sku, id: item.product.id }))
      })

      return new Promise((resolve, reject) => {
        Promise.all(batch).then((data) => {
          this.upload.elabelLoadedCount += data.length
          data.forEach((response) => {
            this.loadedElabel[response.sku] = response.data.editor
          })
          resolve(this.loadElabels(index + batch.length))
        }).catch((err) => {
          if (err && err.response && err.response.status === 429) {
            this.upload.throttling = true
            console.log('Throttling load elabel')
            setTimeout(() => {
              resolve(this.loadElabels(index))
            }, 10000)
          }
        })
      })
    },

    async updateElabels (index) {
      const batch = []
      let array = JSON.parse(JSON.stringify(this.elabelToUpdate))
      array = array.splice(index, this.batchSize)

      if (!array.length) {
        console.log('update done')
        console.log(this.loadedElabel)
        return
      }

      const allLanguages = []
      Languages.forEach((lang) => {
        allLanguages.push(lang.key)
      })

      array.forEach((item) => {
        //  add default template info, merge with array item
        let elabelObj = {}
        console.log('FOR EACh _>', this.productsToUpdate, item.product.sku)
        if (!this.productsToUpdate[item.product.sku].type) {
          if (this.settings.selectedTemplate === 'copy') {
            const defaultObj = JSON.parse(JSON.stringify(this.settings.templateElabel.data.published))
            elabelObj = Utils.mergeDeep(defaultObj, this.productsToUpdate[item.product.sku])
          } else {
            const defaultObj = JSON.parse(JSON.stringify(this.settings.selectedTemplate.data))
            defaultObj.default_language = this.settings.defaultLanguage
            if (~this.settings.availableLanguages.indexOf('all')) {
              defaultObj.available_languages = allLanguages
              defaultObj.reviewed_languages = allLanguages
            } else {
              defaultObj.available_languages = this.settings.availableLanguages
              defaultObj.reviewed_languages = this.settings.availableLanguages
            }
            elabelObj = { ...defaultObj, ...this.productsToUpdate[item.product.sku] }
          }
        } else {
          if (this.settings.selectedTemplate === 'copy') {
            const defaultObj = JSON.parse(JSON.stringify(this.settings.templateElabel.data.published))
            elabelObj = Utils.mergeDeep(defaultObj, this.productsToUpdate[item.product.sku])
          } else {
            elabelObj = { ...this.productsToUpdate[item.product.sku] }
            elabelObj.default_language = this.settings.defaultLanguage
            if (~this.settings.availableLanguages.indexOf('all')) {
              elabelObj.available_languages = allLanguages
              elabelObj.reviewed_languages = allLanguages
            } else {
              elabelObj.available_languages = this.settings.availableLanguages
              elabelObj.reviewed_languages = this.settings.availableLanguages
            }
          }
        }
        batch.push(this.$store.dispatch('updateElabel', { sku: item.product.sku, id: item.product.id, json: { editor: elabelObj, published: elabelObj }, publish: true }))
      })

      return new Promise((resolve, reject) => {
        Promise.all(batch).then((data) => {
          this.upload.eLabelUpdated += batch.length
          resolve(this.updateElabels(index + batch.length))
        }).catch((err) => {
          if (err && err.response && err.response.status === 429) {
            this.upload.throttling = true
            console.log('Throttling udp elabel')
            setTimeout(() => {
              this.updateElabels(index)
            }, 10000)
          }
        })
      })
    },

    async startUpload () {
      if (this.recap?.elabelToCreateCount && this.settings.assignCodeOnUpload) {
        this.$refs['batch-form'].validate()
        if (!this.batchFormValid) {
          return
        }
      }

      this.upload.loading = true
      this.step = 3
      this.upload.step = 1
      if (this.elabelToLoad.length) {
        this.loadElabels(0).then((res) => {
          this.upload.step = 2
          for (const productSku in this.productsToUpdate) {
            // Rough merge for now, advanced merge later
            // this.productsToUpdate[productSku] = { ...this.loadedElabel[productSku], ...this.productsToUpdate[productSku] }
            // if (this.settings.templateElabel && this.settings.selectedTemplate === 'copy') {
            //   this.productsToUpdate[productSku] = Utils.mergeDeep(this.settings, this.productsToUpdate[productSku])
            // } else {

            // delete theme & colors since we want to keep the one from original e-label, also remove brand infos for now
            if (this.loadedElabel[productSku]) {
              delete this.productsToUpdate[productSku].theme
              delete this.productsToUpdate[productSku]['brand-infos']
              delete this.productsToUpdate[productSku].enforce_language_per_country
              // Hack to check serving size to not delete it
              if (this.loadedElabel[productSku]?.['serving-infos']?.serving?.default && !this.productsToUpdate[productSku]?.['serving-infos']?.serving?.default) {
                delete this.productsToUpdate[productSku]['serving-infos']?.serving
              }
              // else if (!this.productsToUpdate[productSku]?.['serving-infos']?.serving?.default) {
              //   this.productsToUpdate[productSku]['serving-infos'].serving.default = ''
              // }
              // ---------
              this.productsToUpdate[productSku] = Utils.mergeDeep(this.loadedElabel[productSku] || {}, this.productsToUpdate[productSku])
            }
          }
          this.elabelToUpdate.forEach((elabel) => {
            elabel.assignCode = this.settings.assignCodeOnUpload
          })
          this.elabelToLoad.forEach((sku) => {
            this.elabelToUpdate.push({ product: sku.product, assignCode: false })
          })
          this.updateElabels(0).then((res) => {
            this.upload.step = 3
            this.assignCodesBatch(0).then((res) => {
              this.upload.completed = true
            })
          })
        })
      } else {
        this.upload.step = 2
        this.elabelToUpdate.forEach((elabel) => {
          elabel.assignCode = this.settings.assignCodeOnUpload
        })
        this.updateElabels(0).then((res) => {
          this.upload.step = 3
          this.assignCodesBatch(0).then((res) => {
            this.upload.completed = true
          })
        })
      }
    },

    parseFile () {
      this.elabelToLoad = []
      this.elabelToUpdate = []
      this.productsNotFound = []
      for (const product in this.productsToUpdate) {
        const found = this.fullProductList.findIndex((p) => {
          return p.sku === product
        })

        if (~found) {
          if (this.fullProductList[found].elabel && this.fullProductList[found].elabel.id) {
            this.elabelToLoad.push({ product: this.fullProductList[found] })
          } else if (!this.fullProductList[found].elabel?.id) {
            // If no elabel, we'll assign a code if they selected
            this.elabelToUpdate.push({ product: this.fullProductList[found], assignCode: true })
          }
          // else {
          //   // Go To create/update directly
          //   this.elabelToUpdate.push({ product: this.fullProductList[found], assignCode: false })
          // }
        } else {
          this.productsNotFound.push(product)
        }
      }
      this.recap.elabelToUpdateCount = this.elabelToLoad.length || 0
      this.recap.elabelToCreateCount = this.elabelToUpdate.length || 0
      this.recap.productsNotFound = this.productsNotFound
      if (!~this.settings.availableLanguages.indexOf('all') && !~this.settings.availableLanguages.indexOf(this.settings.defaultLanguage)) {
        this.settings.availableLanguages.push(this.settings.defaultLanguage)
      }
      this.step = 2
      // this.updateElabels(0).then((res) => {
      //   console.log('ALL DONE UPD', res)
      // })
      // E-label endpoint
      // await ELabelService.saveELabel({ id: target.id, name: target.sku, data: { editor: template, published: {} } })
      // Upload process
      // 1- Fetch product based on keys in productToUpdate
      // - if Product does not exist -> Add to skip list & ignore
      // - else try to load e-label
      //  If exist -> Merge existing & new object. Do we check for type == selected type? Or do we override
      //  If does not exist -> Fill gap with default template, push
      //
    },

    initTranslatedStringAttribute (entry, elabelObject, spreadsheetKey, spreadsheetValue, eLabelKey) {
      if (entry[spreadsheetKey] && entry[spreadsheetKey] !== '#N/A' && entry[spreadsheetKey] !== '#VALUE!') {
        elabelObject[eLabelKey] = entry[spreadsheetKey]
      } else {
        elabelObject[eLabelKey] = {}
        elabelObject[eLabelKey][this.settings.defaultLanguage] = entry[spreadsheetValue]
      }
    },

    // initELabelObject (entry) {
    //   this.productsToUpdate[entry.sku] = JSON.parse(JSON.stringify(this.settings.selectedTemplate))
    //   this.productsToUpdate[entry.sku].default_language = this.defaultLanguage
    //   this.productsToUpdate[entry.sku].available_languages = [this.defaultLanguage]
    //   this.productsToUpdate[entry.sku].reviewed_languages = [this.defaultLanguage]
    // },

    processProductSheet () {
      this.productInfoSheet.forEach((entry) => {
        if (entry.sku) {
          let defaultTemplate
          if (this.settings.selectedTemplate === 'copy') {
            defaultTemplate = JSON.parse(JSON.stringify(this.settings.templateElabel.data.published))
          } else {
            defaultTemplate = JSON.parse(JSON.stringify(this.settings.selectedTemplate.data))
          }
          if (!Object.prototype.hasOwnProperty.call(this.productsToUpdate, entry.sku)) {
            // this.initELabelObject(entry)
            this.productsToUpdate[entry.sku] = {
              'product-infos': { ...defaultTemplate['product-infos'] },
              'serving-infos': { ...defaultTemplate['serving-infos'] }
            }
          }
          this.productsToUpdate[entry.sku]['product-infos'].product_name[this.settings.defaultLanguage] = entry.name || ''
          this.productsToUpdate[entry.sku]['product-infos'].alcohol_volume = entry.alcohol_volume || ''
          this.productsToUpdate[entry.sku]['product-infos'].origin = entry.origin_key && entry.origin_key !== '#N/A' ? [entry.origin_key] : entry.origin && entry.origin.indexOf(',') ? entry.origin.split(',') : entry.origin || ''
          if (entry.net_quantity_ml && typeof entry.net_quantity_ml !== 'string') {
            entry.net_quantity_ml = entry.net_quantity_ml + ''
          }
          if (entry.net_quantity_ml && entry.net_quantity_ml.indexOf(',') > -1) {
            this.productsToUpdate[entry.sku]['product-infos'].volumes = entry.net_quantity_ml.split(',')
          } else {
            this.productsToUpdate[entry.sku]['product-infos'].volumes = entry.net_quantity_ml ? [entry.net_quantity_ml] : []
          }
          this.productsToUpdate[entry.sku]['product-infos'].producing_year = entry.producing_year || ''
          this.productsToUpdate[entry.sku]['product-infos'].wine_sweetness = entry.sweetness_key && entry.sweetness_key !== '#N/A' ? entry.sweetness_key : entry.sugar_content || ''
          this.productsToUpdate[entry.sku]['product-infos'].bottled_in_protected_atmosphere = entry.bottled_msg_key && entry.bottled_msg_key !== '#N/A' ? entry.bottled_msg_key : ''
          this.productsToUpdate[entry.sku]['product-infos'].vine_variety = entry.grape_variety || ''
          this.productsToUpdate[entry.sku]['product-infos'].vintage_year = entry.vintage_year || ''
          this.productsToUpdate[entry.sku]['product-infos'].wine_colour = entry.wine_colour_key && entry.wine_colour_key !== '#N/A' ? entry.wine_colour_key : entry.wine_colour || ''
          this.productsToUpdate[entry.sku]['product-infos'].production_method = entry.production_method_key && entry.production_method_key !== '#N/A' ? entry.production_method_key : entry.production_method || ''

          // convert to string to replace spaces
          if (entry.serving_size_ml) {
            entry.serving_size_ml += ''
            this.productsToUpdate[entry.sku]['serving-infos'].serving = {
              default: entry.serving_size_ml.replaceAll(/\s/g, '') ? entry.serving_size_ml.replaceAll(/\s/g, '') + '' : ''
            }
          }
          this.initTranslatedStringAttribute(entry, this.productsToUpdate[entry.sku]['product-infos'], 'type_key', 'product_type', 'type')
        }
      })
    },

    setLessOperator (key, product) {
      for (const index in this.productsToUpdate[product].nutrition.table) {
        if (this.productsToUpdate[product].nutrition.table[index].key === key) {
          this.productsToUpdate[product].nutrition.table[index].less_operator = true
          return
        }
        if (this.productsToUpdate[product].nutrition.table[index].children) {
          for (const childrenIndex in this.productsToUpdate[product].nutrition.table[index].children) {
            if (this.productsToUpdate[product].nutrition.table[index].children[childrenIndex].key === key) {
              this.productsToUpdate[product].nutrition.table[index].children[childrenIndex].less_operator = true
              return
            }
          }
        }
      }
    },

    processNutritionSheet () {
      const nutritionTable = [
        { key: 'energy', per_100ml: 0, unit: 'kJ', less_operator: false, children: [{ key: null, per_100ml: 0, unit: 'kcal', less_operator: false }] },
        { key: 'fat', per_100ml: 0, unit: 'g', less_operator: false, children: [{ key: 'saturated_fat', per_100ml: 0, unit: 'g', less_operator: false }] },
        { key: 'carbohydrate', per_100ml: 0, unit: 'g', less_operator: false, children: [{ key: 'sugar', per_100ml: 0, unit: 'g', less_operator: false }] },
        { key: 'protein', per_100ml: 0, unit: 'g', less_operator: false },
        { key: 'salt', per_100ml: 0, unit: 'g', less_operator: false }
      ]

      const sheetKeyToNutritionTableMap = {
        energy_kj: 'energy',
        energy_kcal: null,
        fat_g: 'fat',
        saturates_g: 'saturated_fat',
        carbohydrate_g: 'carbohydrate',
        sugar_g: 'sugar',
        protein_g: 'protein',
        salt_g: 'salt'
      }

      this.nutritionSheet.forEach((entry) => {
        if (entry.sku) {
          if (!Object.prototype.hasOwnProperty.call(this.productsToUpdate, entry.sku)) {
            // this.initELabelObject(entry)
            this.productsToUpdate[entry.sku] = {}
          }
          this.productsToUpdate[entry.sku].nutrition = {
            table: JSON.parse(JSON.stringify(nutritionTable)),
            showZeroAsNegligible: entry.show_zero_values_as_negligible || false
          }

          // Parse for < in value & replace , with .
          for (const key in entry) {
            if (typeof entry[key] === 'string' && key !== 'sku' && key !== 'show_zero_values_as_negligible') {
              entry[key] = parseFloat(entry[key].replace(',', '.'))
            }
            if (entry[key] && typeof entry[key] === 'string' && entry[key].indexOf('<') === 0 && key !== 'sku' && key !== 'show_zero_values_as_negligible') {
              entry[key] = parseFloat(entry[key].split('<')[1])
              this.setLessOperator(sheetKeyToNutritionTableMap[key], entry.sku)
            }
          }

          this.productsToUpdate[entry.sku].nutrition.table[0].per_100ml = entry.energy_kj || 0
          this.productsToUpdate[entry.sku].nutrition.table[0].children[0].per_100ml = entry.energy_kcal || 0
          this.productsToUpdate[entry.sku].nutrition.table[1].per_100ml = entry.fat_g || 0
          this.productsToUpdate[entry.sku].nutrition.table[1].children[0].per_100ml = entry.saturates_g || 0
          this.productsToUpdate[entry.sku].nutrition.table[2].per_100ml = entry.carbohydrate_g || 0
          this.productsToUpdate[entry.sku].nutrition.table[2].children[0].per_100ml = entry.sugar_g || 0
          this.productsToUpdate[entry.sku].nutrition.table[3].per_100ml = entry.protein_g || 0
          this.productsToUpdate[entry.sku].nutrition.table[4].per_100ml = entry.salt_g || 0
        }
      })
    },

    processPictogramsSheet () {
      this.pictogramsSheet.forEach((entry) => {
        if (entry.sku) {
          if (!Object.prototype.hasOwnProperty.call(this.productsToUpdate, entry.sku)) {
            // this.initELabelObject(entry)
            this.productsToUpdate[entry.sku] = {}
          }
        } else {
          return
        }
        // Pictogram visible on the following sections: sustainability, responsible-consumption
        if (!Object.prototype.hasOwnProperty.call(this.productsToUpdate[entry.sku], 'responsible-consumption')) {
          this.productsToUpdate[entry.sku]['responsible-consumption'] = { icons: [] }
        }

        if (!Object.prototype.hasOwnProperty.call(this.productsToUpdate[entry.sku], 'sustainability')) {
          this.productsToUpdate[entry.sku].sustainability = { organic_icons: [], recyclability_icons: [], certifications_icons: [] }
        }

        if (entry.internal_icon_id) {
          const prefix = entry.internal_icon_id.split(':')[0]
          const icon = entry.internal_icon_id.split(':')[1]
          if (prefix) {
            switch (prefix) {
              case 'rc':
                if (icon === 'responsible_drinking') {
                  this.productsToUpdate[entry.sku]['responsible-consumption'].responsible_drinking_logo = true
                } else {
                  this.productsToUpdate[entry.sku]['responsible-consumption'].icons.push(icon)
                }
                break
              case 'certifications':
                this.productsToUpdate[entry.sku].sustainability.certifications_icons.push(icon)
                break
              case 'recyclability':
                this.productsToUpdate[entry.sku].sustainability.recyclability_icons.push(icon)
                break
              case 'organic':
                this.productsToUpdate[entry.sku].sustainability.organic_icons.push(icon)
                break
            }
          }
        }
      })
    },

    isContainedCategory (category) {
      return ~['ingredient_category_stabilising_agents', 'ingredient_category_acidity_regulators'].indexOf(category)
    },

    processIngredientsSheet () {
      this.ingredientsSheet.forEach((entry) => {
        if (entry.sku) {
          if (!Object.prototype.hasOwnProperty.call(this.productsToUpdate, entry.sku)) {
            // this.initELabelObject(entry)
            this.productsToUpdate[entry.sku] = {}
          }

          if (!Object.prototype.hasOwnProperty.call(this.productsToUpdate[entry.sku], 'ingredients')) {
            this.productsToUpdate[entry.sku].ingredients = { list: [] }
            if (this.settings.selectedTemplate.key === 'aromatised_wine' || (this.settings.selectedTemplate === 'copy' && this.settings.templateElabel?.data?.published?.type === 'aromatised_wine')) {
              this.productsToUpdate[entry.sku].ingredients.aromatised_wine_list = []
            }
          }

          const ingredient = {
            is_allergen: ((entry.is_allergen && typeof entry.is_allergen === 'boolean') || (entry.is_allergen && typeof entry.is_allergen === 'string' && entry.is_allergen.toLowerCase() === 'true')) || false,
            is_organic: ((entry.is_organic && typeof entry.is_organic === 'boolean') || (entry.is_organic && typeof entry.is_organic === 'string' && entry.is_organic.toLowerCase() === 'true')) || false
          }

          // This condition is for when a user enters only one custom value and no category. In that case we set main_category and take the ingredient name from the category field instead (due to the split per | )
          if ((entry.ingredient_name === '#VALUE!' && entry.ingredient_category === '#VALUE!') || (!entry.ingredient_name && !entry.ingredient_category)) {
            ingredient.category = 'ingredient_category_main_ingredients'
            this.initTranslatedStringAttribute(entry, ingredient, 'ingredient_key', 'ingredient', 'description')
          } else if (!entry.ingredient_name && entry.ingredient_category) {
            ingredient.category = 'ingredient_category_main_ingredients'
            this.initTranslatedStringAttribute(entry, ingredient, 'ingredient_key', 'ingredient_category', 'description')
          } else if (entry.ingredient_category_key && entry.ingredient_category_key !== '#N/A' && entry.ingredient_key) {
            ingredient.category = entry.ingredient_category_key
            this.initTranslatedStringAttribute(entry, ingredient, 'ingredient_key', 'ingredient_name', 'description')
          } else {
            // replace this once we add multi language custom category
            ingredient.category = entry.ingredient_category
            // this.initTranslatedStringAttribute(entry, ingredient, 'ingredient_category_key', 'ingredient_category', 'category')
            this.initTranslatedStringAttribute(entry, ingredient, 'ingredient_key', 'ingredient_name', 'description')
          }

          if (this.isContainedCategory(ingredient.category)) {
            ingredient.is_contained_display = true
          }

          if ((this.settings.selectedTemplate.key !== 'aromatised_wine' && this.settings.templateElabel?.data?.published?.type !== 'aromatised_wine') || (entry.aromatised_wine_key === false || !(entry.aromatised_base_wine_name && entry.aromatised_wine_key !== false))) {
            this.productsToUpdate[entry.sku].ingredients.list.push(ingredient)
          } else if (this.settings.selectedTemplate.key === 'aromatised_wine' || (this.settings.selectedTemplate === 'copy' && this.settings.templateElabel?.data?.published?.type === 'aromatised_wine')) {
            this.productsToUpdate[entry.sku].ingredients.aromatised_wine_list.push(ingredient)
            if (entry.aromatised_wine_key === 'ingredient_wine') {
              this.productsToUpdate[entry.sku].ingredients.aromatised_wine_type = 'ingredient_wine'
            } else {
              this.productsToUpdate[entry.sku].ingredients.aromatised_wine_type = {}
              this.productsToUpdate[entry.sku].ingredients.aromatised_wine_type[this.settings.defaultLanguage] = entry.aromatised_base_wine_name
            }
          }
        }
      })
    },

    processItalianRecyclingSheet () {
      this.italianRecyclingSheet.forEach((entry) => {
        if (entry.sku) {
          if (!Object.prototype.hasOwnProperty.call(this.productsToUpdate, entry.sku)) {
            // this.initELabelObject(entry)
            this.productsToUpdate[entry.sku] = {}
          }

          if (!Object.prototype.hasOwnProperty.call(this.productsToUpdate[entry.sku], 'sustainability')) {
            this.productsToUpdate[entry.sku].sustainability = { italian_recyclability: { list: [], is_enabled: true } }
          } else if (!this.productsToUpdate[entry.sku].sustainability.italian_recyclability?.list) {
            this.productsToUpdate[entry.sku].sustainability.italian_recyclability = { list: [], is_enabled: true }
          }

          const material = {}
          material.index = entry.numbering

          if (entry.custom_name) {
            material.key = entry.custom_name
          } else {
            material.key = entry.material_key
          }
          material.material = entry.material_translation_key
          material.packaging = entry.packaging_key
          material.collectionGuideline = entry.collection_key

          this.productsToUpdate[entry.sku].sustainability.italian_recyclability.list.push(material)
        }
      })
    },

    convertToHTML (string) {
      let result = '<p>'

      result += string.replaceAll('{', '<b>').replaceAll('}', '</b>').replaceAll('\n', '</br>')
      result += '</p>'

      return result
    },

    processFreeTextSheet () {
      this.freeTextSheet.forEach((entry) => {
        if (entry.sku) {
          if (!Object.prototype.hasOwnProperty.call(this.productsToUpdate, entry.sku)) {
            this.productsToUpdate[entry.sku] = {}
          }

          if (entry.field === 'ingredients') {
            if (!Object.prototype.hasOwnProperty.call(this.productsToUpdate[entry.sku], 'ingredients')) {
              this.productsToUpdate[entry.sku].ingredients = { free_text: {}, use_free_form: true }
            } else {
              this.productsToUpdate[entry.sku].ingredients.free_text = {}
              this.productsToUpdate[entry.sku].ingredients.use_free_form = true
            }
          }

          const freeText = {}
          for (const key in entry) {
            if (key !== 'sku' && key !== 'field') {
              const index = Languages.findIndex((lang) => {
                return lang.description === key
              })
              if (~index) {
                freeText[Languages[index].key] = this.convertToHTML(entry[key])
              }
            }
          }
          if (this.productsToUpdate[entry.sku].ingredients) {
            this.productsToUpdate[entry.sku].ingredients.free_text = freeText
          }
        }
      })
    },

    processCountrySpecificSheet () {
      this.countrySpecificSheet.forEach((entry) => {
        if (entry.sku) {
          if (!Object.prototype.hasOwnProperty.call(this.productsToUpdate, entry.sku)) {
            this.productsToUpdate[entry.sku] = {}
          }

          const freeText = {}
          for (const key in entry) {
            if (key !== 'sku' && key !== 'field' && key !== 'internal_field_key') {
              const index = Countries.findIndex((country) => {
                return country.description === key
              })
              if (~index) {
                freeText[Countries[index].key] = entry.internal_field_key === 'impressum' ? this.convertToHTML(entry[key]) : entry[key]
              } else if (key === 'All countries') {
                freeText.default = entry.internal_field_key === 'impressum' ? this.convertToHTML(entry[key]) : entry[key]
              }
            }
          }
          if (entry.internal_field_key.indexOf(':') > -1) {
            const section = entry.internal_field_key.split(':')[0]
            const fieldName = entry.internal_field_key.split(':')[1]
            if (!this.productsToUpdate[entry.sku][section]) {
              this.productsToUpdate[entry.sku][section] = {}
            }
            this.productsToUpdate[entry.sku][section][fieldName] = freeText
          } else if (entry.internal_field_key === 'impressum') {
            this.productsToUpdate[entry.sku].impressum = { free_text: freeText }
          }
        }
      })
    },

    addDefaultTemplateInfos () {
      let defaultTemplate
      if (this.settings.selectedTemplate === 'copy') {
        defaultTemplate = JSON.parse(JSON.stringify(this.settings.templateElabel.data.published))
      } else {
        defaultTemplate = JSON.parse(JSON.stringify(this.settings.selectedTemplate.data))
      }

      for (const product in this.productsToUpdate) {
        const tmp = JSON.parse(JSON.stringify(defaultTemplate))
        this.productsToUpdate[product] = Utils.mergeDeep(tmp, this.productsToUpdate[product])
      }
    },

    readFile (file) {
      this.productsToUpdate = []
      const reader = new FileReader()
      reader.readAsArrayBuffer(file)
      reader.onload = () => {
        const data = new Uint8Array(reader.result)
        const workbook = read(data, { type: 'array' })
        if (workbook.SheetNames.indexOf('Product Information') > -1) {
          const sheet = workbook.Sheets[workbook.SheetNames[workbook.SheetNames.indexOf('Product Information')]]
          this.productInfoSheet = utils.sheet_to_json(sheet)
        }
        if (workbook.SheetNames.indexOf('Ingredients') > -1) {
          const sheet = workbook.Sheets[workbook.SheetNames[workbook.SheetNames.indexOf('Ingredients')]]
          this.ingredientsSheet = utils.sheet_to_json(sheet)
        }
        if (workbook.SheetNames.indexOf('Nutrition') > -1) {
          const sheet = workbook.Sheets[workbook.SheetNames[workbook.SheetNames.indexOf('Nutrition')]]
          this.nutritionSheet = utils.sheet_to_json(sheet)
        }
        if (workbook.SheetNames.indexOf('Pictograms') > -1) {
          const sheet = workbook.Sheets[workbook.SheetNames[workbook.SheetNames.indexOf('Pictograms')]]
          this.pictogramsSheet = utils.sheet_to_json(sheet)
        }
        if (workbook.SheetNames.indexOf('Italian Recycling') > -1) {
          const sheet = workbook.Sheets[workbook.SheetNames[workbook.SheetNames.indexOf('Italian Recycling')]]
          this.italianRecyclingSheet = utils.sheet_to_json(sheet)
        }
        if (workbook.SheetNames.indexOf('Free Text Fields') > -1) {
          const sheet = workbook.Sheets[workbook.SheetNames[workbook.SheetNames.indexOf('Free Text Fields')]]
          this.freeTextSheet = utils.sheet_to_json(sheet)
        }
        if (workbook.SheetNames.indexOf('Country-specific Fields') > -1) {
          const sheet = workbook.Sheets[workbook.SheetNames[workbook.SheetNames.indexOf('Country-specific Fields')]]
          this.countrySpecificSheet = utils.sheet_to_json(sheet)
        }

        this.processProductSheet()
        this.processNutritionSheet()
        this.processIngredientsSheet()
        this.processPictogramsSheet()
        this.processItalianRecyclingSheet()
        this.processFreeTextSheet()
        this.processCountrySpecificSheet()
        this.addDefaultTemplateInfos()
        console.log(this.productsToUpdate)
        this.parseFile()
      }
    },

    onFileChange ($event) {
      this.file = $event.target.files[0]
    }
  },
  async created () {
    await this.$store.dispatch('loadInactiveCodes')
    await this.$store.dispatch('loadFullProductList')
  }
}

</script>

<style lang="sass" scoped>

.bulk-upload
  height: calc(100vh - 64px)
  overflow: scroll
  width: 100%

.delimiter
  height: 1px
  width: 100%
  background: #DCDCDC

.cut-margin
  margin-right: 0px

.comma
  padding-right: 3px

.bulk-row
  width: 90%
  max-width: 1500px
  margin-left: auto
  margin-right: auto
  .v-stepper
    width: 100%

.bulk-upload-ctn
  margin: 20px

.overview-title
  min-width: 150px
  font-size: 18px

.file-summary-card
  .number
    font-size: 20px
  &.new-elabel
    .number
      color: #15BBF0
    background: rgba(21, 187, 240, 0.2)
  &.update-elabel
    .number
      color: #3FC135
    background: rgba(63, 193, 53, 0.2)
  &.not-found
    .number
      color: #F22A42
    background: rgba(242, 42, 66, 0.2)

.upload-btn
  text-align: center
  width: 100%
  height: 100%
  background: #f1f1f1
  padding: 15px
  cursor: pointer
  .v-icon
    width: 35px

  .format-hint
    word-break: break-all
    margin-top: 15px

  .text-uppercase
    color: #00aeef
    font-size: 14px

.hint
  color: #5D5D5D

.custom-stepper
  padding: 0px
  .footer
    margin: 0px 24px 16px 24px
  .content
    padding: 24px 16px
  .loading
    .progress-ctn
      width: 80%
      margin-left: auto
      margin-right: auto
      margin-top: 20px
      .v-progress-linear
        height: 6px !important
    .infos
      padding: 50px 0px
      color: black
      font-size: 20px
      opacity: 1
      .upload-recap
        font-size: 14px
        text-align: center

.error-ctn
  text-align: center
  max-width: 60%
  margin-left: auto
  margin-right: auto

.instructions-ctn
  background-color: #F5F5F5
  .instructions-title
    font-size: 14px
    color: #15BBF0
    text-transform: uppercase

</style>
