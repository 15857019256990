import campaignService from '@/services/campaigns'
import codesService from '@/services/codes'
import * as types from '../mutations'

const state = {
  codes: [],
  filteredCodeCount: 0,
  scmFields: [],
  activeFilters: [],
  loaded: false,
  scmLoaded: false,
  inactiveCodesLoaded: false,
  inactiveCodes: [],
  totalActiveCodesCount: 0,
  totalInactiveCodesCount: 0,
  totalBlacklistedCodesCount: 0,
  totalBlacklistedAssignedCodesCount: 0,
  totalBlacklistedUnassignedCodesCount: 0,
  creatingCode: false,
  isRenameError: false,
  renameErrorMsg: [],
  productCode: null,
  isEditing: false
}

const getters = {
  totalCodes: (state) => state.totalActiveCodesCount + state.totalInactiveCodesCount + state.totalBlacklistedCodesCount,
  totalActiveCodesCount: (state) => state.totalActiveCodesCount,
  totalInactiveCodesCount: (state) => state.totalInactiveCodesCount,
  totalBlacklistedCodesCount: (state) => state.totalBlacklistedCodesCount,
  totalBlacklistedAssignedCodesCount: (state) => state.totalBlacklistedAssignedCodesCount,
  scmFields: (state) => state.scmFields,
  activeScmFields: (state) => {
    return state.scmFields.filter((f) => {
      return !f.is_archived
    })
  },
  scmFieldLoaded: (state) => state.scmLoaded,
  activeCodesFilters: (state) => state.activeFilters,
  filteredCodeCount: (state) => state.filteredCodeCount,
  codes: (state) => state.codes,
  codesLoaded: (state) => state.loaded,
  inactiveCodes: (state) => state.inactiveCodes,
  inactiveCodesLoaded: (state) => state.inactiveCodesLoaded,
  isCreatingCode: (state) => state.creatingCode,
  isCodeRenameError: (state) => state.isRenameError,
  codeRenameErrorMsg: (state) => state.renameErrorMsg,
  selectedProductCode: (state) => state.productCode,
  isCodeEditing: (state) => state.isEditing
}

const actions = {
  async loadScmFields ({ commit }, campaign) {
    const res = await campaignService.loadScmFields(campaign)
    commit(types.SET_SCM_FIELDS, res)
    commit(types.SET_SCM_LOADED, true)
    return res
  },

  async loadActiveCodeCount ({ commit }) {
    const res = await codesService.loadCodes({ activation_status: 1 })
    commit(types.SET_TOTAL_ACTIVE_CODES_COUNT, res.count)
  },

  async loadBlacklistedCodeCount ({ commit }) {
    const res = await codesService.loadCodes({ activation_status: 2, no_product: 'False' })
    // commit(types.SET_TOTAL_BLACKLISTED_CODES_COUNT, res.count)
    commit(types.SET_TOTAL_BLACKLISTED_AND_ASSIGNED_CODES_COUNT, res.count)
  },

  async loadInactiveCodes ({ commit, getters }) {
    // We ignore campaign for now, maybe change later
    const res = await codesService.loadCodes({ activation_status: 0 })
    commit(types.SET_INACTIVE_CODES_LIST, res.results)
    commit(types.SET_TOTAL_INACTIVE_CODES_COUNT, res.count)
    return res
  },

  async loadProductCodeCount ({ commit }, { product }) {
    const res = await codesService.loadCodes({ activation_status: 1, product: product })
    commit(types.SET_PRODUCT_CODE_COUNT, { product, count: res.count })
  },

  addCodeFilter ({ commit, dispatch, getters }, filter) {
    // Check if filter exist first
    const activeFilters = getters.activeCodesFilters

    const match = activeFilters.find((f) => {
      return f.key === filter.key
    })

    if (match) {
      commit(types.UPDATE_CODE_ACTIVE_FILTER, filter)
    } else {
      commit(types.ADD_CODE_ACTIVE_FILTER, filter)
    }
    // dispatch('loadCodes')
  },

  async activateAndAssignCode ({ commit, getters, dispatch }, { product, tags, index }) {
    // Check if inactive code available
    try {
      const inactiveIndex = index >= 0 ? index : 0
      const res = await codesService.activateCode({ code: getters.inactiveCodes[inactiveIndex] })
      // Hack because backend doesnt return an activation date upon activation
      if (!res.activated_at) {
        res.activated_at = new Date()
      }
      commit(types.REMOVE_CODE_FROM_INACTIVE_LIST, res)
      await dispatch('transferCode', { code: res, product: product.sku, tags })
      // If index, means we are doing bulk creation. The codes list is irrelevant there
      if (typeof index !== 'number') {
        res.product = product
        if (product && product.brand) {
          res.brand = product.brand
        }
        commit(types.ADD_CODE_TO_LIST, res)
        commit(types.INCREASE_PRODUCT_CODE_COUNT, product.id)
      }
      return Promise.resolve(res)
    } catch (err) {
      return Promise.reject(err)
    }
  },

  async loadTotalCodes () {
    const res = await codesService.loadCodes({})
    return res
  },

  async loadAllCodes ({ getters }, { count, campaign, product }) {
    const promises = []
    let toLoad = 0

    while (toLoad < count && toLoad <= 2000) {
      promises.push(codesService.loadCodes({ filters: getters.activeCodesFilters, activation_status: 1, campaign, product, offset: toLoad, limit: 500 }))
      toLoad += 500
    }

    return Promise.all(promises).then((data) => {
      let codes = []
      data.forEach((res) => {
        codes = codes.concat(res.results)
      })
      return Promise.resolve(codes)
    }).catch((err) => {
      return Promise.reject(err)
    })
  },

  async loadCodes ({ commit, getters }, { campaign, product, offset, noFilter, cancelToken }) {
    commit(types.SET_CODES_LOADED, false)
    const res = await codesService.loadCodes({ filters: noFilter ? null : getters.activeCodesFilters, activation_status: 1, campaign, product, offset, cancelToken })
    commit(types.SET_CODES_LIST, res)
    commit(types.SET_CODES_LOADED, true)
    return res
  },

  async saveTagsValue ({ commit }, { code, tags }) {
    const tagsCopy = JSON.parse(JSON.stringify(tags))
    const tagArray = [{}]
    let batchIndex = 0
    while (Object.keys(tagsCopy).length) {
      if (Object.keys(tagArray[batchIndex]).length >= 10) {
        batchIndex += 1
        tagArray[batchIndex] = {}
      }
      tagArray[batchIndex][Object.keys(tagsCopy)[0]] = Object.entries(tagsCopy)[0][1]
      delete tagsCopy[Object.keys(tagsCopy)[0]]
    }

    const data = []
    for (const [index, array] of tagArray.entries()) {
      try {
        data[index] = await codesService.updateScmValues({ code, tags: array })
      } catch (err) {
        return Promise.reject(err)
      }
    }

    if (data[0]) {
      let scmData = {}
      data.forEach((res) => {
        if (res.code && res.code.scm_data) {
          scmData = { ...scmData, ...res.code.scm_data }
        }
      })

      commit(types.UPDATE_CODE_SCM_DATA, { extendedId: data[0].code.extended_id, tags: scmData })
      return Promise.resolve()
    }
  },

  async updateTag ({ commit }, { tag }) {
    try {
      const res = await codesService.updateScmField(tag)
      commit(types.UPDATE_SCM_FIELD, res)
      return Promise.resolve(res)
    } catch (err) {
      return Promise.reject(err)
    }
  },

  async createTag ({ commit }, { tag }) {
    try {
      const res = await codesService.createScmField(tag)
      commit(types.ADD_SCM_FIELD_TO_LIST, res)
      return Promise.resolve(res)
    } catch (err) {
      return Promise.reject(err)
    }
  },

  async bulkTransferAndUpdate ({ commit }, { codes, product, tags, status }) {
    try {
      const items = []
      codes.forEach((code) => {
        items.push({
          extended_id: code.message,
          product: product,
          ...tags,
          status: status
        })
      })
      const res = await codesService.scmUpload({ key: 'extended_id', items })
      return Promise.resolve(res)
    } catch (err) {
      console.log(err)
    }
  },

  async transferCode ({ commit }, { code, product, tags }) {
    try {
      const items = [{
        extended_id: code.message,
        product: product,
        ...tags
      }]

      const res = await codesService.scmUpload({ key: 'extended_id', items: items })
      return Promise.resolve(res.codes_affected)
    } catch (err) {
      return Promise.reject(err)
    }
  },

  /* for renaming standard codes to gs1 identifier */
  async activateCodeTransferAndRename ({ state, commit, getters, dispatch }, { product, gs1Code, tags }) {
    try {
      if (state.isRenameError) commit(types.SET_RENAME_CODE_ERROR, false)

      let results = null
      const codeExits = state.productCode && state.productCode?.message
      const inactiveCode = !codeExits ? getters.inactiveCodes[0] : null

      const params = {
        source: codeExits ? state.productCode.message : inactiveCode.message,
        target: gs1Code,
        id_format: 'gs1',
        scm_data: {
          ...tags,
          ...(inactiveCode && {
            product: product.id || product.sku,
            activation_status: 1
          })
        }
      }

      results = await codesService.renameCode(params)
      let code = null

      if (results && Object.keys(results).length > 0) {
        // if code exists, we are renaming the old code to a new code
        if (codeExits) {
          const id = state.productCode.id
          const message = results?.params?.target
          commit(types.UPDATE_CODE_MESSAGE, { id, message })
          commit(types.UPDATE_CODE_TAGS, { id, tags })

          code = {
            ...state.productCode,
            message
          }
        } else {
          // creation of new codes
          // code is returned for setting isNew flag
          code = {
            ...inactiveCode,
            activation_status: 1,
            activated_at: new Date(),
            message: results?.params?.target,
            product,
            brand: {
              ...product.brand
            }
          }
          commit(types.REMOVE_CODE_FROM_INACTIVE_LIST, inactiveCode)
          commit(types.ADD_CODE_TO_LIST, code)
          commit(types.INCREASE_PRODUCT_CODE_COUNT, { product: product.id, code })
        }
      }
      return Promise.resolve(code)
    } catch (err) {
      commit(types.SET_RENAME_CODE_ERROR, true)

      if (err.response) {
        if (err.response.status === 409 && err.response.data) {
          const message = err.response.data?.error_message

          const errorMessage = [message]
          commit(types.SET_RENAME_CODE_ERROR_MESSAGE, { errorMessage })
        } else if (err.response.status === 400 && err.response.data) {
          /**
           * replace 'this field' with required error messages -> Standard code is invalid
           ***/
          const sourceMessage = err.response.data?.source?.toString()?.replace(/This field/g, 'Standard code')
          const targetMessage = err.response.data?.target?.toString()?.replace(/This field/g, 'GS1 identifier code')
          const errorMessage = [sourceMessage, targetMessage]

          commit(types.SET_RENAME_CODE_ERROR_MESSAGE, { errorMessage })
        }
      } else if (err.request) {
        console.log('Request error:', err.request)
      } else {
        console.log('Request setup error:', err.message)
      }

      return Promise.reject(err)
    }
  }
}

const mutations = {
  [types.SET_SCM_FIELDS] (state, fields) {
    state.scmFields = fields
  },

  [types.ADD_SCM_FIELD_TO_LIST] (state, field) {
    state.scmFields.unshift(field)
  },

  [types.UPDATE_SCM_FIELD] (state, field) {
    const index = state.scmFields.findIndex((f) => {
      return f.key === field.key
    })
    if (~index) {
      state.scmFields[index] = field
    }
  },

  [types.SET_SCM_LOADED] (state, status) {
    state.scmLoaded = status
  },

  [types.SET_CODES_LOADED] (state, status) {
    state.loaded = status
  },

  [types.CLEAR_ACTIVE_CODE_FILTERS] (state) {
    state.activeFilters = []
  },

  [types.ADD_CODE_ACTIVE_FILTER] (state, filter) {
    state.activeFilters.push(filter)
  },

  [types.SET_TOTAL_INACTIVE_CODES_COUNT] (state, count) {
    state.totalInactiveCodesCount = count
  },

  [types.SET_TOTAL_BLACKLISTED_AND_ASSIGNED_CODES_COUNT] (state, count) {
    state.totalBlacklistedAssignedCodesCount = count
  },

  [types.SET_TOTAL_BLACKLISTED_CODES_COUNT] (state, count) {
    state.totalBlacklistedCodesCount = count
  },

  [types.SET_TOTAL_ACTIVE_CODES_COUNT] (state, count) {
    state.totalActiveCodesCount = count
  },

  [types.REMOVE_CODE_ACTIVE_FILTER] (state, filter) {
    const index = state.activeFilters.findIndex((f) => f.key === filter.key)
    if (~index) {
      state.activeFilters.splice(index, 1)
    }
  },

  [types.UPDATE_CODE_ACTIVE_FILTER] (state, filter) {
    const index = state.activeFilters.findIndex((f) => f.key === filter.key)
    if (~index) {
      state.activeFilters[index] = filter
    }
  },

  [types.UPDATE_CODE_SCM_DATA] (state, { extendedId, tags }) {
    // do things
    const index = state.codes.findIndex((c) => c.message === extendedId)
    if (~index) {
      state.codes[index].scm_data = tags
    }
  },

  [types.SET_CODE_ACTIVE_FILTERS] (state, filters) {
    state.activeFilters = filters
  },

  [types.ADD_CODE_TO_LIST] (state, code) {
    state.codes.unshift(code)
    state.filteredCodeCount += 1
  },

  [types.SET_CODES_LIST] (state, res) {
    state.codes = res.results
    state.filteredCodeCount = res.count
  },

  [types.REMOVE_CODE_FROM_INACTIVE_LIST] (state, code) {
    const index = state.inactiveCodes.findIndex((c) => c.message === code.message)
    if (~index) {
      state.inactiveCodes.splice(index, 1)
    }
  },

  [types.SET_INACTIVE_CODES_LIST] (state, codes) {
    state.inactiveCodes = codes
    state.inactiveCodesLoaded = true
  },

  [types.SET_IS_CREATING_CODE] (state, payload) {
    state.creatingCode = payload
  },

  [types.SET_RENAME_CODE_ERROR] (state, payload) {
    state.isRenameError = payload
  },

  [types.SET_RENAME_CODE_ERROR_MESSAGE] (state, payload) {
    state.renameErrorMsg = payload.errorMessage
  },

  [types.UPDATE_CODE_MESSAGE] (state, { id, message }) {
    const index = state.codes.findIndex((c) => c.id === id)
    if (~index) {
      state.codes[index].message = message
    }
  },

  [types.UPDATE_CODE_TAGS] (state, { id, tags }) {
    const index = state.codes.findIndex((c) => c.id === id)
    if (~index) {
      const scmData = state.codes[index].scm_data
      state.codes[index].scm_data = {
        ...scmData,
        ...tags
      }
    }
  },

  [types.SET_SELECTED_PRODUCT_CODE] (state, payload) {
    state.productCode = payload
  },

  [types.SET_CODE_EDITING] (state, payload) {
    state.isEditing = payload
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
