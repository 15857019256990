import * as types from '../mutations'
import workorderService from '@/services/workorders'

const state = {
  loaded: false,
  prefix: ''
}

const getters = {
  prefix: (state) => state.prefix,
  workorderLoaded: (state) => state.loaded
}

const actions = {
  async loadCodesInfos ({ commit, dispatch }) {
    try {
      const res = await workorderService.loadWorkorders()

      commit(types.SET_PREFIX, res[0].url_prefix)
      commit(types.SET_WORKORDER_LOADED, true)
      return Promise.resolve()
    } catch (err) {
      return Promise.reject(err)
    }
  }
}

const mutations = {

  [types.SET_PREFIX] (state, prefix) {
    state.prefix = prefix
  },

  [types.SET_WORKORDER_LOADED] (state, status) {
    state.loaded = status
  }

}

export default {
  state,
  getters,
  actions,
  mutations
}
