import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "elabel-editor"
  }, [_c(VProgressLinear, {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.loading,
      expression: "loading"
    }],
    attrs: {
      "indeterminate": "",
      "color": "primary"
    }
  }), _c('div', {
    staticClass: "d-flex align-center pt-5 pb-3"
  }, [_c('router-link', {
    attrs: {
      "to": "/products"
    }
  }, [_c(VBtn, {
    staticClass: "ml-3",
    attrs: {
      "icon": ""
    }
  }, [_c(VIcon, {
    attrs: {
      "color": "black"
    }
  }, [_vm._v("mdi-arrow-left")])], 1)], 1), _c('div', {
    staticClass: "page-title mono pl-3"
  }, [_vm._v("Scantrust E-label Management Tool")]), _c(VSpacer)], 1), _vm.loadIframe ? _c('iframe', {
    attrs: {
      "id": "elabel-editor-frame",
      "frameborder": "0",
      "src": this.editorUrl
    }
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }