import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VPagination } from 'vuetify/lib/components/VPagination';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "products"
  }, [_c(VRow, {
    staticClass: "ma-0"
  }, [_c(VCol, {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.campaigns && _vm.campaigns.length > 1,
      expression: "campaigns && campaigns.length > 1"
    }],
    staticClass: "campaign-list flex-grow-0 flex-shrink-0"
  }, [_c('div', {
    staticClass: "page-title flex-shrink-0 mono pl-10 pb-4"
  }, [_vm._v("Campaigns")]), _vm._l(_vm.campaigns, function (campaign) {
    return _c('div', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: !_vm.loadingCampaigns,
        expression: "!loadingCampaigns"
      }],
      key: campaign.id,
      staticClass: "campaign-item pl-10 pr-2 py-2",
      class: {
        'selected': _vm.selectedCampaign.id == campaign.id
      },
      on: {
        "click": function click($event) {
          return _vm.selectCampaign(campaign);
        }
      }
    }, [_vm._v(" " + _vm._s(campaign.name) + " ")]);
  }), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.loadingCampaigns,
      expression: "loadingCampaigns"
    }],
    staticClass: "campaign-skeleton-ctn"
  }, [_c(VSkeletonLoader, {
    attrs: {
      "width": "100%",
      "type": "list-item"
    }
  })], 1)], 2), _c(VCol, {
    staticClass: "product-list flex-grow-1",
    class: {
      'pl-2': _vm.campaigns.length == 1
    }
  }, [_c('div', {
    staticClass: "product-actions-ctn d-flex mb-2 pt-2 align-center flex-wrap flex-grow-1"
  }, [!_vm.isAvailable('qrmanager:product:csv') ? _c(VBtn, {
    staticClass: "md-raised primary mr-4",
    on: {
      "click": _vm.openCreateProductModal
    }
  }, [_c(VIcon, {
    staticClass: "mr-2"
  }, [_vm._v("mdi-plus")]), _vm._v("New Product ")], 1) : _vm._e(), _vm.isAvailable('qrmanager:product:csv') ? _c(VBtn, {
    staticClass: "md-raised primary mr-4",
    on: {
      "click": function click($event) {
        _vm.manageProductDialog = true;
      }
    }
  }, [_c(VIcon, {
    staticClass: "mr-2",
    attrs: {
      "color": "black"
    }
  }, [_vm._v("mdi-plus")]), _vm._v("New product ")], 1) : _vm._e(), _vm.isAvailable('qrmanager:codes:list') && _vm.products.length ? _c('router-link', {
    attrs: {
      "to": "/codes"
    }
  }, [_c(VBtn, {
    attrs: {
      "text": "",
      "color": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.goToManageAll();
      }
    }
  }, [_c(VIcon, {
    staticClass: "mr-2"
  }, [_vm._v("mdi-qrcode")]), _vm._v("all QR codes")], 1)], 1) : _vm._e(), _vm.isAvailable('qrmanager:elabel_bulk_upload') ? _c('router-link', {
    attrs: {
      "to": "/bulk-upload"
    }
  }, [_c(VBtn, {
    attrs: {
      "text": "",
      "color": "primary"
    }
  }, [_c(VIcon, {
    staticClass: "mr-2"
  }, [_vm._v("mdi-cloud-upload")]), _vm._v("upload e-label data")], 1)], 1) : _vm._e(), _c(VSpacer), _c('codes-quota', {
    staticClass: "mr-3"
  })], 1), _c(VForm, {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.filteredProductCount || !_vm.filteredProductCount && _vm.search,
      expression: "filteredProductCount || (!filteredProductCount && search)"
    }],
    staticClass: "pt-2 pb-2",
    on: {
      "submit": _vm.filterProducts
    }
  }, [_c(VTextField, {
    attrs: {
      "label": "Search products",
      "solo": "",
      "hide-details": "",
      "append-icon": "mdi-magnify"
    },
    model: {
      value: _vm.search,
      callback: function callback($$v) {
        _vm.search = $$v;
      },
      expression: "search"
    }
  })], 1), _c('div', {
    staticClass: "d-flex align-center total-ctn"
  }, [_c(VSpacer), !_vm.loadingProducts && _vm.products.length ? _c('div', {
    staticClass: "search-label pt-2 mono"
  }, [_vm._v("Products: " + _vm._s(_vm.filteredProductCount))]) : _vm._e()], 1), _vm._l(_vm.products, function (product) {
    return _c('div', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: !_vm.loadingProducts,
        expression: "!loadingProducts"
      }],
      key: product.id,
      staticClass: "product-card mb-2"
    }, [_c(VRow, {
      staticClass: "ma-0"
    }, [_c('div', {
      staticClass: "product-picture flex-grow-0 pa-0",
      style: {
        'background-image': 'url(' + _vm.getProductImage(product) + ')'
      }
    }), _c('div', {
      staticClass: "flex-grow-1 pa-0 pl-3"
    }, [_c('div', {
      staticClass: "d-flex align-center mt-2"
    }, [_c('span', {
      staticClass: "product-title font-weight-medium pr-3"
    }, [_vm._v(_vm._s(product.name))]), _c(VTooltip, {
      attrs: {
        "top": ""
      },
      scopedSlots: _vm._u([{
        key: "activator",
        fn: function fn(_ref) {
          var on = _ref.on,
            attrs = _ref.attrs;
          return [_c(VBtn, _vm._g(_vm._b({
            staticClass: "ma-1 ml-3",
            attrs: {
              "x-small": "",
              "icon": ""
            },
            on: {
              "click": function click($event) {
                return _vm.editProduct(product);
              }
            }
          }, 'v-btn', attrs, false), on), [_c(VIcon, {
            attrs: {
              "color": "#15bbf0"
            }
          }, [_vm._v("mdi-pencil")])], 1)];
        }
      }], null, true)
    }, [_c('span', [_vm._v("Edit Product")])])], 1), _c('div', {
      staticClass: "product-description"
    }, [_vm._v("SKU: " + _vm._s(product.sku))]), _c('div', {
      staticClass: "product-description"
    }, [_vm._v("Brand: [" + _vm._s(product.brand.reference) + "] " + _vm._s(product.brand.name))]), _vm.isAvailable('qrmanager:elabel') ? _c('div', {
      staticClass: "d-flex elabel-status align-center"
    }, [_c('span', [_vm._v("E-Label Status:")]), _c('span', {
      staticClass: "status-pill mx-2",
      class: "".concat(_vm.getELabelStatus(product))
    }), _vm.getELabelStatus(product) === 'published' ? _c('span', [_vm._v("Published")]) : _vm.getELabelStatus(product) === 'draft' ? _c('span', [_vm._v("Draft")]) : _c('span', [_vm._v("Not Created")])]) : _vm._e()]), _c('div', {
      staticClass: "d-flex align-center ml-auto"
    }, [_vm.isAvailable('qrmanager:elabel') ? _c(VBtn, {
      staticClass: "mr-3",
      attrs: {
        "raised": ""
      },
      on: {
        "click": function click($event) {
          return _vm.goToElabel(product);
        }
      }
    }, [_vm._v("Edit E-Label Data")]) : _vm._e(), _c('div', {
      staticClass: "get-code-ctn pa-2",
      on: {
        "click": function click($event) {
          return _vm.manageCodes(product);
        }
      }
    }, [_c('div', {
      staticClass: "d-flex justify-center pt-4"
    }, [_c(VIcon, {
      staticClass: "mr-2",
      attrs: {
        "color": "black"
      }
    }, [_vm._v("mdi-qrcode")]), _c('div', {
      staticClass: "code-count font-weight-light"
    }, [_vm._v("x " + _vm._s(product.code_count ? product.code_count : 0))])], 1), _c(VBtn, {
      staticClass: "mt-1",
      attrs: {
        "color": "primary",
        "small": "",
        "text": ""
      }
    }, [_vm._v("Get QR Codes")])], 1)], 1)])], 1);
  }), _vm.loadingProducts ? _c('div', {
    staticClass: "skeleton-ctn"
  }, [_c(VSkeletonLoader, {
    attrs: {
      "width": "100%",
      "type": "avatar, paragraph"
    }
  })], 1) : _vm._e(), !_vm.loadingProducts && !_vm.products.length ? _c('div', {
    staticClass: "empty-state-ctn"
  }, [_c('img', {
    staticClass: "guide-arrow",
    attrs: {
      "src": require("@/assets/guide_arrow.svg")
    }
  }), _c('img', {
    staticClass: "product-cta",
    attrs: {
      "src": require("@/assets/product-cta.jpeg")
    }
  }), _c('div', {
    staticClass: "py-4 font-weight-medium"
  }, [_vm._v(" Start engaging your customers with QR codes."), _c('br'), _vm._v(" Create a new product to get started! ")])]) : _vm._e(), _c('div', {
    staticClass: "pagination-ctn mt-5 mb-4"
  }, [_c(VPagination, {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.loadingProducts && _vm.filteredProductCount > 0,
      expression: "!loadingProducts && filteredProductCount > 0"
    }],
    attrs: {
      "color": "primary",
      "total-visible": 7,
      "length": _vm.nbPages
    },
    model: {
      value: _vm.page,
      callback: function callback($$v) {
        _vm.page = $$v;
      },
      expression: "page"
    }
  })], 1)], 2)], 1), _c(VDialog, {
    attrs: {
      "width": "900",
      "persistent": true
    },
    model: {
      value: _vm.productDialog,
      callback: function callback($$v) {
        _vm.productDialog = $$v;
      },
      expression: "productDialog"
    }
  }, [_c('product-form', {
    attrs: {
      "campaign": this.selectedCampaign.id,
      "product": this.$route.params && this.$route.params.product ? this.$route.params.product : {},
      "product-id": this.$route.params && this.$route.params.id ? this.$route.params.id : null
    },
    on: {
      "on-product-created": _vm.onProductCreated,
      "on-close-dialog": function onCloseDialog($event) {
        return _vm.handleCloseDialog();
      }
    }
  })], 1), _c(VDialog, {
    attrs: {
      "width": "600"
    },
    model: {
      value: _vm.manageProductDialog,
      callback: function callback($$v) {
        _vm.manageProductDialog = $$v;
      },
      expression: "manageProductDialog"
    }
  }, [_c(VCard, [_c(VCardTitle, {
    staticClass: "headline"
  }, [_vm._v("Create / Update Products")]), _c('div', {
    staticClass: "pt-4"
  }, [_c(VRow, {
    staticClass: "ma-0 pa-0",
    attrs: {
      "align": "center",
      "justitfy": "center"
    }
  }, [_c(VCol, {
    staticClass: "text-center"
  }, [_c(VBtn, {
    attrs: {
      "fab": ""
    },
    on: {
      "click": _vm.openCreateProductModal
    }
  }, [_c(VIcon, {
    attrs: {
      "color": "primary"
    }
  }, [_vm._v(" mdi-plus ")])], 1), _c('div', {
    staticClass: "pt-4"
  }, [_vm._v("New product")])], 1), _c(VCol, {
    staticClass: "text-center"
  }, [_c('product-csv', {
    on: {
      "open-dialog": function openDialog($event) {
        _vm.manageProductDialog = false;
      }
    }
  })], 1)], 1)], 1), _c(VCardActions, [_c(VSpacer), _c(VBtn, {
    attrs: {
      "color": "primary",
      "text": ""
    },
    on: {
      "click": function click($event) {
        _vm.manageProductDialog = false;
      }
    }
  }, [_vm._v("close")])], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }