import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VStepper } from 'vuetify/lib/components/VStepper';
import { VStepperContent } from 'vuetify/lib/components/VStepper';
import { VStepperHeader } from 'vuetify/lib/components/VStepper';
import { VStepperItems } from 'vuetify/lib/components/VStepper';
import { VStepperStep } from 'vuetify/lib/components/VStepper';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c(VBtn, {
    attrs: {
      "text": "",
      "color": "primary"
    },
    on: {
      "click": function click($event) {
        _vm.dialog = true;
      }
    }
  }, [_c(VIcon, {
    staticClass: "mr-2"
  }, [_vm._v("$csv")]), _vm._v("create more")], 1), _c(VDialog, {
    attrs: {
      "width": "900"
    },
    model: {
      value: _vm.dialog,
      callback: function callback($$v) {
        _vm.dialog = $$v;
      },
      expression: "dialog"
    }
  }, [_c('div', {
    staticClass: "title d-flex align-center"
  }, [_c('div', [_vm._v("Create More QR Codes by CSV")]), _c('info-tooltip', {
    staticClass: "ml-3",
    attrs: {
      "position": "bottom",
      "content": "This feature will create 1 code for each product listed in your CSV file. It will also apply tag values if specified."
    }
  })], 1), _c(VStepper, {
    attrs: {
      "value": _vm.step
    }
  }, [_c(VStepperHeader, [_c(VStepperStep, {
    attrs: {
      "step": "1"
    }
  }, [_vm._v("Prepare")]), _c(VDivider), _c(VStepperStep, {
    attrs: {
      "step": "2"
    }
  }, [_vm._v("Batch ID")]), _c(VDivider), _c(VStepperStep, {
    attrs: {
      "step": "3"
    }
  }, [_vm._v("Upload")])], 1), _c(VStepperItems, [_c(VStepperContent, {
    attrs: {
      "step": "1"
    }
  }, [_c('div', {
    staticClass: "content"
  }, [_c('div', {
    staticClass: "important font-weight-bold"
  }, [_vm._v("Your CSV header must contain:")]), _c(VLayout, [_c(VFlex, {
    staticClass: "infos font-weight-medium pr-2"
  }, [_vm._v(" - sku"), _c('br'), _c('br'), _vm._v(" Other columns supported:"), _c('br'), _vm._v(" - tags keys "), _c('info-tooltip', {
    attrs: {
      "content": "Download the CSV template below in order to get the full list of available tags"
    }
  }), _c('br'), _c('br'), _vm._v(" This feature will create 1 QR code per line in the CSV. Use comma (,) as the delimiter. ")], 1), _c(VFlex, [_c('div', {
    staticClass: "file-upload-ctn"
  }, [_c(VLayout, {
    staticClass: "upload-btn",
    attrs: {
      "align-center": "",
      "justify-center": "",
      "column": ""
    },
    on: {
      "click": function click($event) {
        return _vm.clickOnFileInput($event, 'csv-file');
      }
    }
  }, [!_vm.csv || !_vm.csv.name ? _c('div', {
    staticClass: "text-xs-center"
  }, [_c(VIcon, {
    attrs: {
      "x-large": "",
      "color": "primary"
    }
  }, [_vm._v("mdi-cloud-upload")]), _c('div', {
    staticClass: "text-uppercase font-weight-bold"
  }, [_vm._v("Choose file")]), _c('div', {
    staticClass: "format-hint"
  }, [_vm._v(" Supported formats:"), _c('br'), _vm._v(" CSV ")])], 1) : _vm._e(), _vm.csv && _vm.csv.name ? _c('div', {
    staticClass: "text-xs-center"
  }, [_c(VIcon, {
    attrs: {
      "x-large": "",
      "color": "primary"
    }
  }, [_vm._v("mdi-cloud-upload")]), _c('div', {
    staticClass: "text-uppercase font-weight-bold"
  }, [_vm._v("Change File")]), _c('div', {
    staticClass: "format-hint"
  }, [_vm._v(" " + _vm._s(_vm.csv.name) + " ")])], 1) : _vm._e()]), _c('input', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: false,
      expression: "false"
    }],
    ref: "csv-file",
    attrs: {
      "accept": ".csv",
      "type": "file"
    },
    on: {
      "change": function change($event) {
        return _vm.onFileChange($event, _vm.csv);
      }
    }
  })], 1)])], 1)], 1), _c(VLayout, {
    staticClass: "footer"
  }, [_c(VFlex, {
    attrs: {
      "grow": ""
    }
  }, [_c(VBtn, {
    attrs: {
      "text": "",
      "color": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.downloadCSVTemplate();
      }
    }
  }, [_vm._v("Download CSV Template"), _c(VIcon, {
    staticClass: "ml-2"
  }, [_vm._v("mdi-cloud-download")])], 1)], 1), _c(VFlex, {
    attrs: {
      "shrink": ""
    }
  }, [_c(VBtn, {
    attrs: {
      "text": "",
      "color": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.close();
      }
    }
  }, [_vm._v("Cancel")]), _c(VBtn, {
    attrs: {
      "raised": "",
      "color": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.goToBatchStep();
      }
    }
  }, [_vm._v("Next")])], 1)], 1)], 1), _c(VStepperContent, {
    attrs: {
      "step": "2"
    }
  }, [_c('div', {
    staticClass: "important font-weight-bold"
  }, [_vm._v("Upload Batch (optional):")]), _c(VLayout, {
    staticClass: "content"
  }, [_c(VFlex, {
    staticClass: "infos font-weight-medium"
  }, [_vm._v(" Label this upload batch so you can easily filter these codes later. You can also download this batch after the uploading is complete. Enter something such as today’s date or simply “Batch Number 123”. "), _c(VTextField, {
    staticClass: "mt-3 mb-6",
    attrs: {
      "label": "Upload Batch Label",
      "counter": "50",
      "rules": _vm.batchRules
    },
    model: {
      value: _vm.batchName,
      callback: function callback($$v) {
        _vm.batchName = $$v;
      },
      expression: "batchName"
    }
  })], 1)], 1), _c(VLayout, {
    staticClass: "footer"
  }, [_c(VSpacer), _c(VFlex, {
    attrs: {
      "shrink": ""
    }
  }, [_c(VBtn, {
    attrs: {
      "text": "",
      "color": "primary"
    },
    on: {
      "click": function click($event) {
        _vm.step = 1;
      }
    }
  }, [_vm._v("Back")]), _c(VBtn, {
    attrs: {
      "raised": "",
      "color": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.parseCSV();
      }
    }
  }, [_vm._v("Next")])], 1)], 1)], 1), _c(VStepperContent, {
    staticClass: "custom-stepper",
    attrs: {
      "step": "3"
    }
  }, [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.completed,
      expression: "!completed"
    }],
    staticClass: "loading"
  }, [_c(VProgressLinear, {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.completed,
      expression: "!completed"
    }],
    attrs: {
      "indeterminate": true
    }
  }), _c(VLayout, {
    attrs: {
      "align-center": "",
      "justify-center": "",
      "row": "",
      "fill-height": ""
    }
  }, [_c(VFlex, {
    staticClass: "text-center infos font-weight-bold",
    attrs: {
      "shrink": ""
    }
  }, [_vm._v("Uploading... (" + _vm._s(this.uploadCount) + " / " + _vm._s(this.uploadData.length) + ")"), _c('br'), this.throttling ? _c('div', [_vm._v("(Upload in progress, do not close this window)")]) : _vm._e()])], 1)], 1), _vm.completed && _vm.uploadErrors.length ? _c('div', {
    staticClass: "content"
  }, [_c('div', {
    staticClass: "important font-weight-bold"
  }, [_vm._v("Warning:")]), !_vm.quotaError ? _c('div', {
    staticClass: "infos font-weight-medium"
  }, [_vm._v("Something is wrong with your CSV. Please fix the issues below and try again:"), _c('br'), _c('br')]) : _vm._e(), _vm._l(_vm.uploadErrors, function (line, index) {
    return _c('div', {
      key: "errors-".concat(index),
      staticClass: "infos font-weight-medium"
    }, [_vm._v(" " + _vm._s(line) + " ")]);
  }), _vm.hasWarning && !_vm.quotaError ? _c('div', {
    staticClass: "infos mt-5 font-weight-medium"
  }, [_vm._v("If you still want to proceed with the upload, these lines will be excluded."), _c('br'), _c('br')]) : _vm._e(), _vm.hasWarning ? _c(VBtn, {
    staticClass: "mt-5",
    attrs: {
      "raised": "",
      "color": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.forceUpload();
      }
    }
  }, [_vm._v("Upload anyways")]) : _vm._e()], 2) : _vm._e(), _vm.completed && !_vm.uploadErrors.length ? _c('div', {
    staticClass: "content"
  }, [_c('div', {
    staticClass: "important font-weight-bold"
  }, [_vm._v("Upload Complete!")]), _vm.createCount > 0 ? _c('div', {
    staticClass: "infos font-weight-medium"
  }, [_vm._v(_vm._s(this.createCount) + " Codes(s) have been created.")]) : _vm._e()]) : _vm._e(), _c(VLayout, {
    staticClass: "footer",
    attrs: {
      "row": ""
    }
  }, [_c(VSpacer), _vm.completed && _vm.uploadErrors.length ? _c(VBtn, {
    attrs: {
      "text": "",
      "color": "primary"
    },
    on: {
      "click": function click($event) {
        _vm.step = 1;
      }
    }
  }, [_vm._v("Back")]) : _vm._e(), _c(VBtn, {
    attrs: {
      "text": "",
      "color": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.close(true);
      }
    }
  }, [_vm._v("Close")]), _vm.completed && !_vm.uploadErrors.length && this.batchName ? _c(VBtn, {
    attrs: {
      "raised": "",
      "color": "primary"
    },
    on: {
      "click": _vm.addBatchFilter
    }
  }, [_vm._v("download this batch")]) : _vm._e()], 1)], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }