<template>
  <div class="code-tag-editor">
    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <v-btn :disabled="isDisabled" v-bind="attrs" v-on="on" @click="openDialog" color="primary" icon><v-icon>mdi-tag-multiple-outline</v-icon></v-btn>
      </template>
      <span>Edit tags</span>
    </v-tooltip>

    <v-dialog width="600" scrollable persistent v-model="dialog">
      <v-card  class="edit-tag-dialog" v-if="mode == 'edit' ">
        <v-card-title>
          Edit Tags
          <v-spacer></v-spacer>
          <v-btn :disabled="hasLimit('tags') && filteredTags.length >= getLimit('tags')" color="primary" @click="mode = 'new' " text><v-icon class="mr-2">mdi-tag-plus-outline</v-icon>new tag</v-btn>
        </v-card-title>

        <v-card-text class="py-5" v-if="sortedTags.length > 1 || tagsToDelete.length > 0">
          <div v-if="codes.length == 1 || !showEditRadio" class="pl-2 pb-4 tag-hint font-weight-light">
            Leave the tag value empty if you do not want to attach it to the selected code(s).
          </div>
          <div v-if="codes.length > 1 && showEditRadio" class="pl-2 pb-4 tag-hint font-weight-light">
            The selected codes may have different values under the same tag. Choose how you want to proceed with bulk editing.
            <v-radio-group v-model="updateMode" class="radio-ctn font-weight-medium">
              <v-radio value="standard" label="Leave empty tags as is."></v-radio>
              <v-radio value="clear-empty-tags" label='Treat empty tags as "removing this tag from the selected codes".'></v-radio>
            </v-radio-group>
          </div>

          <div class="d-flex tag-header font-weight-bold mx-3">
            <div class="name">Tag Name</div>
            <div>Tag Value</div>
            <v-spacer></v-spacer>
            <div v-if="hasLimit('tags')">{{filteredTags.length}}/{{getLimit('tags')}}</div>
          </div>
          <div v-if="tagsToDelete.length > 0 && !filteredTags.length" class="pl-3">
            No tags.
          </div>
          <tag-input class="ma-2" :label="tag.key" v-on:delete-tag="deleteTag(tag)" :tag-value.sync="codeTags[tag.key]" v-for="tag in filteredTags" :key="tag.key" :rules="editRules"></tag-input>
        </v-card-text>

        <v-card-text class="py-5" v-if="sortedTags.length <= 1 && !tagsToDelete.length">
          You do not have any tags yet. Create a new tag to get started!
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="close">Close</v-btn>
          <v-btn text color="primary" v-if="sortedTags.length <= 1 && tagsToDelete.length === 0" @click="mode = 'new'" :loading="saving" >Ok</v-btn>
          <v-btn text color="primary" v-if="sortedTags.length > 1 || tagsToDelete.length > 0" @click="saveTags" :loading="saving" >save</v-btn>
        </v-card-actions>
      </v-card>

      <v-card class="edit-tag-dialog" v-if="mode == 'new' ">
        <v-card-title>
          New Tag
        </v-card-title>
        <v-card-text class="py-5">
          <div class="pb-5">
            The tracking tags allow you to identify your QR codes easily. You can use them to search, filter and track your codes.<br><br>
            Each tag consists of a <strong>name </strong> and a <strong>value</strong>. You will be able to set a value after creating a tag.
          </div>
          <v-form ref="form" v-model="valid">
            <div class="tag-input d-flex">
              <div class="label font-weight-bold pt-2">Tag Name</div>
              <v-text-field :counter="50" placeholder="E.g. Origin" dense single-line outlined v-model="form.key" :rules="newRules"></v-text-field>
            </div>
            <div class="errors-ctn">
              <div v-for="error in errors" :key="error">{{error}}</div>
            </div>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="goToEdit">Back</v-btn>
          <v-btn text color="primary" @click="createTag" :loading="saving" >Create</v-btn>
        </v-card-actions>
      </v-card>

    </v-dialog>
  </div>
</template>

<script>

import TagInput from '@/components/TagInput'
import { mapGetters } from 'vuex'
import amplitude from 'amplitude-js'
import Utils from '@/utils/utils'

export default {
  name: 'CodeTagEditor',
  components: {
    TagInput
  },
  computed: {
    ...mapGetters(['hasLimit', 'getLimit']),
    filteredTags () {
      return this.sortedTags.filter((tag) => {
        return !Utils.excludedTagRegEx.test(tag.key)
      })
    }
  },
  props: ['codes', 'tags', 'campaign', 'editMode', 'product', 'selectAll', 'count', 'isDisabled'],
  data () {
    return {
      showEditRadio: false,
      allCodes: [],
      batchSize: 10,
      dialog: false,
      saving: false,
      codeTags: {},
      updateMode: 'standard',
      sortedTags: [],
      mode: 'edit',
      errors: [],
      tagsToDelete: [],
      editRules: [
        // v => (v && v.length <= 10) || 'Tag value cannot exceed 100 characters'
      ],
      newRules: [
        v => !!v || 'Tag name is required',
        v => /^[\S]+$/.test(v) || 'Tag name cannot contain space',
        v => /^[a-zA-Z0-9_]*$/.test(v) || 'Tag name can only contain letters and the _ character',
        v => v.length <= 50 || 'Tag name cannot exceed 50 characters'
      ],
      valid: false,
      form: {
        key: '',
        name: '',
        type: 'text',
        // position?
        is_public: true,
        campaign: '',
        is_lu: false
      }
    }
  },
  methods: {
    resetState () {
      this.form.key = ''
      this.form.name = ''
      this.errors = []
    },

    goToEdit () {
      this.resetState()
      this.mode = 'edit'
    },

    openDialog () {
      // Check if some of the selected codes already have existing SCM data filled
      // If yes: We show the radio button to pick an edit more
      // If not: We hide the radio choice and keep it as default
      if (this.codes.length <= 20) {
        this.showEditRadio = this.codes.some((code) => {
          for (const [key] of Object.entries(code.scm_data)) {
            if (code.scm_data[key].length > 0) {
              return true
            }
          }
        })
      } else if (this.selectAll && this.codes.length > 20) {
        this.showEditRadio = true
      }

      if (this.codes.length > 1) {
        this.codeTags = {}
      } else {
        this.codeTags = JSON.parse(JSON.stringify(this.codes[0].scm_data))
      }
      this.sortTags()
      amplitude.getInstance().logEvent('click edit tags')
      this.dialog = true
    },

    sortTags () {
      const usedTags = this.tags.filter((tag) => {
        return this.codeTags[tag.key]
      })

      const unusedTags = this.tags.filter((tag) => {
        return !this.codeTags[tag.key]
      })

      this.sortedTags = usedTags.concat(unusedTags)
      this.sortedTags = this.sortedTags.filter((tag) => {
        return !tag.is_archived
      })
    },

    async deleteTag (tag) {
      tag.is_archived = true
      this.sortTags()
      this.tagsToDelete.push(tag)
    },

    async createTag () {
      this.$refs.form.validate()
      if (!this.valid) {
        return
      }
      this.saving = true
      this.form.campaign = this.campaign
      this.form.name = this.form.key
      try {
        const found = this.tags.find((tag) => {
          return tag.key === this.form.key
        })

        let tag = {}
        if (found && found.id && found.is_archived) {
          this.form.is_archived = false
          this.form.id = found.id
          tag = await this.$store.dispatch('updateTag', { tag: this.form })
          if (this.tagsToDelete.length) {
            const matchIndex = this.tagsToDelete.findIndex((tag) => {
              return tag.id === found.id
            })
            if (~matchIndex) {
              this.tagsToDelete.splice(matchIndex, 1)
            }
          }
        } else {
          tag = await this.$store.dispatch('createTag', { tag: this.form })
        }

        amplitude.getInstance().logEvent('create tag')
        this.saving = false
        this.resetState()
        this.sortedTags.push(tag)
        this.mode = 'edit'
      } catch (err) {
        this.errors = []
        if (err.response && err.response.data) {
          for (const [key] of Object.entries(err.response.data)) {
            err.response.data[key].forEach((msg) => {
              if (key !== 'non_field_errors') {
                this.errors.push(msg)
              } else {
                this.errors.push('Error while creating a new tag. Make sure this tag doesn\'t already exist in this campaign.')
              }
            })
          }
        }
        this.saving = false
      }
    },

    close () {
      this.codeTags = {}
      if (this.tagsToDelete.length) {
        this.tagsToDelete.forEach((tag) => {
          tag.is_archived = false
        })
      }
      this.tagsToDelete = []
      this.dialog = false
      this.mode = 'edit'
    },

    async uploadBatch (index, isRetry) {
      // Take next BatchSize Products
      let array
      if (this.allCodes.length) {
        array = JSON.parse(JSON.stringify(this.allCodes))
      } else {
        array = JSON.parse(JSON.stringify(this.codes))
      }
      array = array.splice(index, this.batchSize)

      if (!array.length) {
        this.saving = false
        this.close()
        this.$emit('update-done')
        return
      }
      try {
        // delete batch to not set it on multiple codes
        delete this.codeTags.batch
        await this.$store.dispatch('bulkTransferAndUpdate', { codes: array, tags: this.codeTags })
        this.uploadBatch(index + this.batchSize)
      } catch (err) {
        if (err && err.response && err.response.status === 429) {
          this.throttling = true
          console.log('Throttling')
          setTimeout(() => {
            this.uploadBatch(index, true)
          }, 10000)
        }
      }
    },

    async saveTags () {
      this.saving = true
      amplitude.getInstance().logEvent('update tag values')
      try {
        if (this.tagsToDelete.length) {
          this.tagsToDelete.forEach((tag) => {
            this.$store.dispatch('updateTag', { tag })
          })
        }

        // CHANGE LOGIC TO LOOP
        if (this.editMode === 'bulk') {
          if (this.codes.length > 1 && this.updateMode === 'clear-empty-tags') {
            let test = {}
            this.tags.forEach((tag) => {
              if (tag.key !== 'scantrust_upload_batch') {
                test[tag.key] = ''
              }
            })
            test = {
              ...test,
              ...this.codeTags
            }
            this.codeTags = test
          }

          // Remove existing tag value for removed tags
          if (this.tagsToDelete.length) {
            this.tagsToDelete.forEach((tag) => {
              this.codeTags[tag.key] = ''
            })
          }

          // If there are more code to update than the one provided as parameters
          if (this.selectAll && this.count > 20) {
            console.log('more than 20 codes selected, loading all')
            this.allCodes = await this.$store.dispatch('loadAllCodes', { count: this.count ? this.count : 0, campaign: this.campaign, product: this.product })
            this.uploadBatch(0)
          } else {
            console.log('less than 20')
            this.uploadBatch(0)
          }
        } else {
          // Remove existing tag value for removed tags
          if (this.tagsToDelete.length) {
            this.tagsToDelete.forEach((tag) => {
              this.codeTags[tag.key] = ''
            })
          }

          await this.$store.dispatch('saveTagsValue', { tags: this.codeTags, code: this.codes[0] })
          this.saving = false
          this.close()
        }
      } catch (err) {
        this.saving = false
      }
    }
  }
}

</script>

<style lang="sass" scoped>

.edit-tag-dialog
  .tag-hint
    color: black
    font-size: 17px

  .radio-ctn
    label
      color: black !important

  .tag-input
    .label
      padding: 0px 10px
      color: black
      min-width: 140px

  .tag-header
    border-bottom: 1px solid rgba(0,0,0,0.3)
    .name
      min-width: 150px

.errors-ctn
  color: red
  padding-left: 150px

</style>
