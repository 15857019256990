// AUTH
export const SCANTRUST_AUTH_SUCCESS = 'SCANTRUST_AUTH_SUCCESS'
export const SCANTRUST_AUTH_ERROR = 'SCANTRUST_AUTH_ERROR'
export const SET_USER_PROFILE = 'SET_USER_PROFILE'

// REDIRECTION
export const SET_REDIRECTION_VARIABLES = 'SET_REDIRECTION_VARIABLES'
export const SET_ACTIVATION_STATUSES = 'SET_ACTIVATION_STATUSES'
export const SET_APPS_LIST = 'SET_APPS_LIST'
export const SET_CUSTOM_REGIONS = 'SET_CUSTOM_REGIONS'
export const SET_REDIRECTION_RULES = 'SET_REDIRECTION_RULES'
export const ADD_REDIRECTION_RULE = 'ADD_REDIRECTION_RULE'
export const UPDATE_REDIRECTION_RULE = 'UPDATE_REDIRECTION_RULE'
export const ARCHIVE_REDIRECTION_RULE = 'ARCHIVE_REDIRECTION_RULE'
export const UNARCHIVE_REDIRECTION_RULE = 'UNARCHIVE_REDIRECTION_RULE'
export const UPDATE_REDIRECTION_RULES = 'UPDATE_REDIRECTION_RULES'
export const ADD_REDIRECTION_RULE_DESTINATION = 'ADD_REDIRECTION_RULE_DESTINATION'
export const UPDATE_REDIRECTION_RULE_DESTINATION = 'UPDATE_REDIRECTION_RULE_DESTINATION'
export const DELETE_REDIRECTION_RULE_DESTINATION = 'DELETE_REDIRECTION_RULE_DESTINATION'
export const UPDATE_REDIRECTION_RULE_STATUS = 'UPDATE_REDIRECTION_RULE_STATUS'
export const LOAD_RULE_DESTINATIONS = 'LOAD_RULE_DESTINATIONS'
export const SET_RULES_SCM_FIELDS = 'SET_RULES_SCM_FIELDS'
export const SET_SCAN_RESULTS = 'SET_SCAN_RESULTS'
export const SET_ACTIVE_RULES_FILTERS = 'SET_ACTIVE_RULES_FILTERS'
export const CLEAR_ACTIVE_RULES_FILTERS = 'CLEAR_ACTIVE_RULES_FILTERS'
export const SET_COMPUTED_AUTH_RESULTS = 'SET_COMPUTED_AUTH_RESULTS'
export const TOGGLE_REDIRECTION_SIMULATION_MODE = 'TOGGLE_REDIRECTION_SIMULATION_MODE'
export const SET_SIMULATING = 'SET_SIMULATING'
export const SET_SIMULATION_RESULTS = 'SET_SIMULATION_RESULTS'
export const SET_SIMULATION_CODE = 'SET_SIMULATION_CODE'

// PRODUCTS
export const SET_PRODUCT_LIST = 'SET_PRODUCT_LIST'
export const SET_PRODUCT_LOADING = 'SET_PRODUCT_LOADING'
export const SET_SELECTED_CAMPAIGN = 'SET_SELECTED_CAMPAIGN'
export const SET_CAMPAIGN_CONSUMER_OPTIONS = 'SET_CAMPAIGN_CONSUMER_OPTIONS'
export const SET_PRODUCT_SAVING = 'SET_PRODUCT_SAVING'
export const UPDATE_PRODUCT = 'UPDATE_PRODUCT'
export const CREATE_PRODUCT = 'CREATE_PRODUCT'
export const INCREASE_PRODUCT_CODE_COUNT = 'INCREASE_PRODUCT_CODE_COUNT'
export const SET_PRODUCT_COUNT = 'SET_PRODUCT_COUNT'
export const SET_FULL_PRODUCT_LIST = 'SET_FULL_PRODUCT_LIST'

// CAMPAIGNS
export const SET_CAMPAIGN_LIST = 'SET_CAMPAIGN_LIST'
export const SET_CAMPAIGN_LOADING = 'SET_CAMPAIGN_LOADING'
export const SET_DASHBOARD_LOAD_STATUS = 'SET_DASHBOARD_LOAD_STATUS'

// DASHBOARD
export const SET_SCANS_LIST = 'SET_SCANS_LIST'
export const SET_DASHBOARD_CAMPAIGN = 'SET_DASHBOARD_CAMPAIGN'
export const SET_SCAN_SUMMARY = 'SET_SCAN_SUMMARY'
export const SET_DASHBOARD_TIMEFRAME = 'SET_DASHBOARD_TIMEFRAME'
export const CLEAR_DASHBOARD_FILTERS = 'CLEAR_DASHBOARD_FILTERS'
export const ADD_DASHBOARD_FILTER = 'ADD_DASHBOARD_FILTER'
export const REMOVE_DASHBOARD_FILTER = 'REMOVE_DASHBOARD_FILTER'
export const UPDATE_DASHBOARD_FILTERS = 'UPDATE_DASHBOARD_FILTERS'

// LANDING PAGES
export const SET_LANDING_PAGE_LIST = 'SET_LANDING_PAGE_LIST'
export const SET_LANDING_PAGE_LOADING = 'SET_LANDING_PAGE_LOADING'
export const UPDATE_PRODUCT_UPDATE_STATUS = 'UPDATE_PRODUCT_UPDATE_STATUS'
export const SET_LANDING_PAGE_LOADED = 'SET_LANDING_PAGE_LOADED'

// QR
export const SET_QR_TEMPLATES_LIST = 'SET_QR_TEMPLATES_LIST'
export const DELETE_QR_TEMPLATE = 'DELETE_QR_TEMPLATE'
export const ADD_QR_TEMPLATE = 'ADD_QR_TEMPLATE'
export const UPDATE_QR_TEMPLATE = 'UPDATE_QR_TEMPLATE'

// CODES
export const SET_SCM_FIELDS = 'SET_SCM_FIELDS'
export const ADD_SCM_FIELD_TO_LIST = 'ADD_SCM_FIELD_TO_LIST'
export const UPDATE_SCM_FIELD = 'UPDATE_SCM_FIELD'
export const SET_SCM_LOADED = 'SET_SCM_LOADED'
export const ADD_CODE_ACTIVE_FILTER = 'ADD_CODE_ACTIVE_FILTER'
export const REMOVE_CODE_ACTIVE_FILTER = 'REMOVE_CODE_ACTIVE_FILTER'
export const UPDATE_CODE_ACTIVE_FILTER = 'UPDATE_CODE_ACTIVE_FILTER'
export const CLEAR_ACTIVE_CODE_FILTERS = 'CLEAR_ACTIVE_CODE_FILTERS'
export const SET_CODE_ACTIVE_FILTERS = 'SET_CODE_ACTIVE_FILTERS'
export const SET_CODES_LIST = 'SET_CODES_LIST'
export const SET_CODES_LOADED = 'SET_CODES_LOADED'
export const ADD_CODE_TO_LIST = 'ADD_CODE_TO_LIST'
export const SET_INACTIVE_CODES_LIST = 'SET_INACTIVE_CODES_LIST'
export const REMOVE_CODE_FROM_INACTIVE_LIST = 'REMOVE_CODE_FROM_INACTIVE_LIST'
export const UPDATE_CODE_SCM_DATA = 'UPDATE_CODE_SCM_DATA'
export const SET_TOTAL_ACTIVE_CODES_COUNT = 'SET_TOTAL_ACTIVE_CODES_COUNT'
export const SET_PRODUCT_CODE_COUNT = 'SET_PRODUCT_CODE_COUNT'
export const SET_TOTAL_INACTIVE_CODES_COUNT = 'SET_TOTAL_INACTIVE_CODES_COUNT'
export const SET_TOTAL_BLACKLISTED_CODES_COUNT = 'SET_TOTAL_BLACKLISTED_CODES_COUNT'
export const SET_TOTAL_BLACKLISTED_AND_ASSIGNED_CODES_COUNT = 'SET_TOTAL_BLACKLISTED_AND_ASSIGNED_CODES_COUNT'
export const SET_IS_CREATING_CODE = 'SET_IS_CREATING_CODE'
export const SET_RENAME_CODE_ERROR = 'SET_RENAME_CODE_ERROR'
export const SET_RENAME_CODE_ERROR_MESSAGE = 'SET_RENAME_CODE_ERROR_MESSAGE'
export const UPDATE_CODE_MESSAGE = 'UPDATE_CODE_MESSAGE'
export const UPDATE_CODE_TAGS = 'UPDATE_CODE_TAGS'
export const SET_SELECTED_PRODUCT_CODE = 'SET_SELECTED_PRODUCT_CODE'
export const SET_CODE_EDITING = 'SET_CODE_EDITING'

// WO
export const SET_TOTAL_NUMBER_CODES = 'SET_TOTAL_NUMBER_CODES'
export const SET_PREFIX = 'SET_PREFIX'
export const SET_WORKORDER_LOADED = 'SET_WORKORDER_LOADED'

// SNACKBAR
export const SHOW_SNACK_BAR = 'SHOW_SNACK_BACK'
export const DISSMISS_SNACK_BAR = 'DISSMISS_SNACK_BAR'

// BRANDS
export const SET_BRAND_LIST = 'SET_BRAND_LIST'
export const SET_DEFAULT_BRAND = 'SET_DEFAULT_BRAND'
