import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSlider } from 'vuetify/lib/components/VSlider';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VStepper } from 'vuetify/lib/components/VStepper';
import { VStepperContent } from 'vuetify/lib/components/VStepper';
import { VStepperHeader } from 'vuetify/lib/components/VStepper';
import { VStepperItems } from 'vuetify/lib/components/VStepper';
import { VStepperStep } from 'vuetify/lib/components/VStepper';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "download-ctn"
  }, [_vm.mode == 'codes' ? _c(VBtn, {
    attrs: {
      "color": "primary",
      "raised": "",
      "disabled": !_vm.count
    },
    on: {
      "click": _vm.open
    }
  }, [_vm._v("Download")]) : _vm._e(), _vm.mode == 'single' ? _c(VTooltip, {
    attrs: {
      "top": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on,
          attrs = _ref.attrs;
        return [_c(VBtn, _vm._g(_vm._b({
          attrs: {
            "color": "primary",
            "icon": ""
          },
          on: {
            "click": _vm.open
          }
        }, 'v-btn', attrs, false), on), [_c(VIcon, {
          attrs: {
            "color": "#15bbf0"
          }
        }, [_vm._v("mdi-qrcode")])], 1)];
      }
    }], null, false, 37656637)
  }, [_c('span', [_vm._v("Preview & Download")])]) : _vm._e(), _vm.mode == 'product' ? _c(VTooltip, {
    attrs: {
      "top": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref2) {
        var on = _ref2.on,
          attrs = _ref2.attrs;
        return [_c(VBtn, _vm._g(_vm._b({
          attrs: {
            "disabled": _vm.isDisabled,
            "color": "primary",
            "icon": ""
          },
          on: {
            "click": _vm.open
          }
        }, 'v-btn', attrs, false), on), [_c(VIcon, {
          attrs: {
            "color": "#15bbf0"
          }
        }, [_vm._v("mdi-download")])], 1)];
      }
    }], null, false, 1613264683)
  }, [_c('span', [_vm._v("Download")])]) : _vm._e(), _c(VDialog, {
    attrs: {
      "width": "600"
    },
    model: {
      value: _vm.deleteQrDialog,
      callback: function callback($$v) {
        _vm.deleteQrDialog = $$v;
      },
      expression: "deleteQrDialog"
    }
  }, [_c(VCard, [_c(VCardTitle, [_vm._v(" Delete Confirmation ")]), _c(VCardText, {
    staticClass: "my-2"
  }, [_vm._v(" Do you really want to delete this QR Code Template? ")]), _c(VCardActions, [_c('div', {
    staticClass: "mr-auto"
  }), _c(VBtn, {
    staticClass: "mr-2",
    attrs: {
      "text": ""
    },
    on: {
      "click": function click($event) {
        _vm.deleteQrDialog = false;
      }
    }
  }, [_vm._v("Cancel")]), _c(VBtn, {
    staticClass: "mr-2",
    attrs: {
      "color": "red",
      "loading": _vm.deletingTemplate,
      "text": ""
    },
    on: {
      "click": _vm.deleteQrTemplate
    }
  }, [_vm._v("Delete")])], 1)], 1)], 1), _c(VDialog, {
    attrs: {
      "scrollable": "",
      "width": _vm.mode === 'single' ? 800 : 650,
      "persistent": ""
    },
    model: {
      value: _vm.dialog,
      callback: function callback($$v) {
        _vm.dialog = $$v;
      },
      expression: "dialog"
    }
  }, [_c(VCard, {
    staticClass: "pb-2"
  }, [_c(VCardTitle, [_vm.mode != 'single' ? _c('span', [_vm._v("Download Format")]) : _vm._e(), _vm.mode == 'single' ? _c('span', [_vm._v("Preview and Download")]) : _vm._e(), _c(VSpacer), _c(VBtn, {
    attrs: {
      "color": "primary",
      "text": ""
    },
    on: {
      "click": _vm.close
    }
  }, [_vm._v("cancel")])], 1), _c(VCardText, {
    staticClass: "pa-0"
  }, [_c(VStepper, {
    model: {
      value: _vm.step,
      callback: function callback($$v) {
        _vm.step = $$v;
      },
      expression: "step"
    }
  }, [_c(VStepperHeader, [_c(VStepperStep, {
    attrs: {
      "step": "1"
    }
  }, [_vm._v("Designing your QR Code")]), _c(VDivider), _c(VStepperStep, {
    attrs: {
      "step": "2"
    }
  }, [_vm._v("File format")])], 1), _c(VStepperItems, [_c(VStepperContent, {
    attrs: {
      "step": "1"
    }
  }, [_c(VRow, {
    staticClass: "ma-0"
  }, [_c(VCol, [_vm.qrTemplates.length ? _c('div', [_c('div', {
    staticClass: "label font-weight-bold pb-1"
  }, [_vm._v("QR Code Templates")]), _c(VSelect, {
    attrs: {
      "items": _vm.qrTemplates,
      "clearable": "",
      "solo": ""
    },
    scopedSlots: _vm._u([{
      key: "selection",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_c('div', {
          staticClass: "select-qr-preview mr-3"
        }, [_c('img', {
          staticClass: "template-qr-preview",
          attrs: {
            "src": _vm.getTemplatePreviewUrl(item)
          }
        }), item.image ? _c('img', {
          staticClass: "template-qr-logo",
          style: {
            backgroundColor: item.background
          },
          attrs: {
            "src": _vm.toDataUrl(item.image)
          }
        }) : _vm._e()]), _c(VListItemContent, [_c(VListItemTitle, {
          domProps: {
            "innerHTML": _vm._s(item.filename)
          }
        }), _c(VListItemSubtitle, {
          domProps: {
            "innerHTML": _vm._s("".concat(item.color, " - ").concat(item.background))
          }
        })], 1)];
      }
    }, {
      key: "item",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_c('div', {
          staticClass: "select-qr-preview mr-3"
        }, [_c('img', {
          staticClass: "template-qr-preview",
          attrs: {
            "src": _vm.getTemplatePreviewUrl(item)
          }
        }), item.image ? _c('img', {
          staticClass: "template-qr-logo",
          style: {
            backgroundColor: item.background
          },
          attrs: {
            "src": _vm.toDataUrl(item.image)
          }
        }) : _vm._e()]), _c(VListItemContent, [_c(VListItemTitle, {
          domProps: {
            "innerHTML": _vm._s(item.filename)
          }
        }), _c(VListItemSubtitle, {
          domProps: {
            "innerHTML": _vm._s("".concat(item.color, " - ").concat(item.background))
          }
        })], 1), _c(VListItemAction, [_c(VBtn, {
          attrs: {
            "icon": ""
          },
          on: {
            "click": function click($event) {
              return _vm.deleteQrTemplateConfirm(item);
            }
          }
        }, [_c(VIcon, {
          attrs: {
            "color": "red lighten-1"
          }
        }, [_vm._v("mdi-delete")])], 1)], 1)];
      }
    }], null, false, 3090673912),
    model: {
      value: _vm.selectedTemplate,
      callback: function callback($$v) {
        _vm.selectedTemplate = $$v;
      },
      expression: "selectedTemplate"
    }
  })], 1) : _vm._e(), _c('div', {
    staticClass: "label font-weight-bold pb-1"
  }, [_vm._v("QR Color:")]), _c('color-input', {
    attrs: {
      "color": _vm.form.color
    },
    on: {
      "update:color": function updateColor($event) {
        return _vm.$set(_vm.form, "color", $event);
      }
    }
  }), _c('div', {
    staticClass: "label font-weight-bold mt-5 pb-1"
  }, [_vm._v("Background Color:")]), _c('color-input', {
    attrs: {
      "color": _vm.form.background
    },
    on: {
      "update:color": function updateColor($event) {
        return _vm.$set(_vm.form, "background", $event);
      }
    }
  }), _vm.hasConstrastIssue ? _c('div', {
    staticClass: "color-error pt-5 d-flex"
  }, [_c(VIcon, {
    staticClass: "mr-3",
    attrs: {
      "color": "red"
    }
  }, [_vm._v("mdi-alert")]), _c('div', {
    staticClass: "font-weight-bold"
  }, [_vm._v("The colors your selected do not have enough contrast."), _c('br'), _vm._v(" This QR code might not be readable by most QR scanners.")])], 1) : _vm._e()], 1), _vm.mode === 'single' ? _c(VCol, {
    staticClass: "flex-grow-0 preview-col pr-0"
  }, [_c('div', {
    staticClass: "qr-preview d-flex flex-column justify-center"
  }, [_c('div', {
    staticClass: "qr-preview-ctn"
  }, [_c('img', {
    staticClass: "qr",
    attrs: {
      "src": _vm.previewUrl
    }
  }), _vm.form.image ? _c('img', {
    staticClass: "logo",
    style: {
      backgroundColor: _vm.form.background
    },
    attrs: {
      "src": _vm.toDataUrl(_vm.form.image)
    }
  }) : _vm._e()]), _c('div', {
    staticClass: "mt-auto mb-2"
  }), _c('ImageCropper', {
    attrs: {
      "ratio": 1,
      "label": "Upload Logo",
      "input-key": "qr-logo"
    },
    on: {
      "image-selected": _vm.onImageSelect
    }
  }), _c('div', [_vm.form.image ? _c(VBtn, {
    staticClass: "inset mt-3",
    attrs: {
      "text": ""
    },
    on: {
      "click": _vm.deleteLogo
    }
  }, [_vm._v("Remove logo")]) : _vm._e()], 1)], 1)]) : _vm._e()], 1)], 1), _c(VStepperContent, {
    attrs: {
      "step": "2"
    }
  }, [_c(VRow, [_c(VCol, [_c('div', {
    staticClass: "mb-5"
  }, [_c('div', {
    staticClass: "label font-weight-bold pb-1"
  }, [_vm._v("choose file format:")]), _c('div', _vm._l(_vm.formats, function (format) {
    return _c('span', {
      key: format,
      staticClass: "pill font-weight-bold mr-2",
      class: {
        'selected': format === _vm.form.format
      },
      on: {
        "click": function click($event) {
          return _vm.selectFormat(format);
        }
      }
    }, [_vm._v(" " + _vm._s(format) + " ")]);
  }), 0)]), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.hasDPI,
      expression: "hasDPI"
    }],
    staticClass: "mb-5"
  }, [_c('div', {
    staticClass: "label font-weight-bold pb-1"
  }, [_vm._v("choose DPI:")]), _c('div', _vm._l(_vm.dpis, function (dpi) {
    return _c('span', {
      key: dpi,
      staticClass: "pill font-weight-bold mr-2",
      class: {
        'selected': dpi === _vm.form.dpi
      },
      on: {
        "click": function click($event) {
          return _vm.selectDPI(dpi);
        }
      }
    }, [_vm._v(" " + _vm._s(dpi) + "dpi ")]);
  }), 0)]), _c('div', {
    staticClass: "mb-5"
  }, [_c('div', {
    staticClass: "label font-weight-bold pb-1"
  }, [_vm._v("QR Code Dimensions (mm)")]), _c('div', {
    staticClass: "hint"
  }, [_vm._v("Please choose tiff, svg, eps or pdf file format for other dimensions and resize the QR code offline")]), _c(VSlider, {
    staticClass: "pt-10 px-2",
    attrs: {
      "step": "5",
      "max": "30",
      "min": "10",
      "thumb-label": "always",
      "ticks": "",
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.qrSize,
      callback: function callback($$v) {
        _vm.qrSize = $$v;
      },
      expression: "qrSize"
    }
  }), _c(VRow, {
    staticClass: "ma-0",
    attrs: {
      "justify": "space-between"
    }
  }, [_c('div', {
    staticClass: "size-hint font-weight-bold"
  }, [_vm._v(" Small"), _c('br'), _vm._v("(10mm) ")]), _c('div', {
    staticClass: "size-hint font-weight-bold"
  }, [_vm._v(" Medium"), _c('br'), _vm._v("(20mm) ")]), _c('div', {
    staticClass: "size-hint font-weight-bold"
  }, [_vm._v(" Large"), _c('br'), _vm._v("(30mm) ")])])], 1), _c('div', {
    staticClass: "mb-5"
  }, [_c('div', {
    staticClass: "label font-weight-bold pb-1"
  }, [_vm._v("Naming convention:")]), _c(VTextField, {
    ref: "filename",
    attrs: {
      "dense": ""
    },
    scopedSlots: _vm._u([{
      key: "append",
      fn: function fn() {
        return [_c('span', [_vm._v(" ." + _vm._s(_vm.form.format) + " ")])];
      },
      proxy: true
    }]),
    model: {
      value: _vm.form.filename,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "filename", $$v);
      },
      expression: "form.filename"
    }
  }), _c('div', {
    staticClass: "hint"
  }, [_vm._v(" Click the naming components below to add them to your files name ")]), _c('div', _vm._l(_vm.generalFields, function (field) {
    return _c('span', {
      key: "general-field-".concat(field),
      staticClass: "pill font-weight-bold mr-2 mb-2",
      class: {
        'selected': _vm.isGeneralFieldSelected(field)
      },
      on: {
        "click": function click($event) {
          return _vm.toggleGeneralField(field);
        }
      }
    }, [_vm._v(_vm._s(field))]);
  }), 0), _c('div', {
    staticClass: "hint"
  }, [_vm._v("Tags:")]), _c('div', _vm._l(_vm.activeScmFields, function (field) {
    return _c('span', {
      key: field.key,
      staticClass: "pill font-weight-bold mr-2 mb-2",
      class: {
        'selected': _vm.isTagSelected(field)
      },
      on: {
        "click": function click($event) {
          return _vm.toggleTag(field);
        }
      }
    }, [_vm._v(_vm._s(field.key === 'scantrust_upload_batch' ? 'Upload Batch' : field.key))]);
  }), 0)], 1)])], 1)], 1)], 1), _vm.step === 1 ? _c(VRow, {
    staticClass: "dialog-footer pa-3"
  }, [_c('div', {
    staticClass: "mr-auto"
  }), _c(VBtn, {
    staticClass: "mr-2",
    attrs: {
      "text": ""
    },
    on: {
      "click": _vm.close
    }
  }, [_vm._v("Cancel")]), _c(VBtn, {
    staticClass: "mr-2",
    on: {
      "click": function click($event) {
        _vm.step = 2;
      }
    }
  }, [_vm._v("Next")])], 1) : _vm._e(), _vm.step === 2 ? _c(VRow, {
    staticClass: "dialog-footer pa-3 align-center"
  }, [_vm.templateToUpdate || _vm.templateToCreate ? _c(VCheckbox, {
    staticClass: "ml-2",
    attrs: {
      "hide-details": "",
      "dense": "",
      "label": "Save as a template for next download"
    },
    model: {
      value: _vm.createTemplateToggle,
      callback: function callback($$v) {
        _vm.createTemplateToggle = $$v;
      },
      expression: "createTemplateToggle"
    }
  }) : _vm._e(), _c('div', {
    staticClass: "mr-auto"
  }), _c(VBtn, {
    staticClass: "mr-2",
    attrs: {
      "text": ""
    },
    on: {
      "click": function click($event) {
        _vm.step = 1;
      }
    }
  }, [_vm._v("Back")]), _c(VBtn, {
    staticClass: "mr-2",
    attrs: {
      "loading": _vm.loading,
      "disabled": _vm.count > 1000 || _vm.codes.length > 1000
    },
    on: {
      "click": _vm.download
    }
  }, [_vm._v("Download")])], 1) : _vm._e()], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }