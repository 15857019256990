<template>
  <div class="codes">
    <div class="d-flex align-center pt-5">
      <router-link to="/products">
        <v-btn class="ml-3" icon><v-icon color="black">mdi-arrow-left</v-icon></v-btn>
      </router-link>
      <div class="page-title mono pl-3">All QR Codes</div>
      <codes-csv ref="csv-add-codes" v-on:codes-activated="resetBulkSelection" class="ml-4"></codes-csv>
    </div>
    <div class="d-flex align-center pt-5 mx-8">
      <codes-search class="flex-grow-1 mr-4" ref="codeSearch" :campaign="selectedCampaign.id"></codes-search>
      <!-- <v-btn raised color="primary">Download</v-btn> -->
      <download mode="codes" :count="selectAllEnabled ? filteredCodeCount : selectedCodesIndexes.length" :select-all="selectAllEnabled" :codes="selectedCodes" :campaign="selectedCampaign.id"></download>
    </div>

    <div v-show="filteredProductCount" class="d-flex align-center search-settings mx-8">
      <div class="d-flex align-center flex-wrap">
        <v-checkbox hide-details :value="selectAllEnabled" @click="toggleSelectAll()"></v-checkbox>
        <div class="search-label mr-4">Selected: {{ selectAllEnabled ? filteredCodeCount : selectedCodesIndexes.length }}</div>
        <div v-if="activeCodesFilters.length" class="search-label mr-4">Results: {{filteredCodeCount}}</div>
        <div class="search-label mr-2">Filters:</div>
        <div class="search-label" v-if="!activeCodesFilters.length">None</div>
        <v-chip class="ml-2" close color="#f1f1f1" text-color="#0789b2" @click="editActiveFilter(filter)" @click:close="removeFilter(filter)" :key="`active-filters-${index}`" v-for="(filter, index) in activeCodesFilters">
          {{filter.name}}: {{filter.value}}
        </v-chip>
      </div>
      <v-spacer></v-spacer>
      <div v-show="codesLoaded" class="search-label mr-3 flex-shrink-0 mono">Total: {{filteredCodeCount}}</div>
    </div>

    <div class="code-list-ctn mx-8">
      <v-progress-linear v-show="!codesLoaded" indeterminate></v-progress-linear>
      <div v-show="codesLoaded" >
        <div class="code-line py-1 d-flex align-center" :class="{'selected': selectedCodesIndexes.indexOf(codeIndex) >= 0 }" v-for="(code, codeIndex) in codes" :key="code.id">
          <div class="d-flex flex-wrap align-center">
            <v-checkbox hide-details color="#15bbf0" :value="selectedCodesIndexes.indexOf(codeIndex) >= 0" @click="toggleSelectCode(codeIndex)"></v-checkbox>
            <div class="mono">{{code.message}} /</div>
            <div @click="addProductFilter(code.product)" class="url product-name pl-2"> {{code.product.name}}</div>
            <v-chip class="ml-2" v-show="value" @click="addFilter(name, value)" color="#f1f1f1" text-color="#0789b2" :key="`code-${code.id}-tags-${name}-${value}`" v-for="(value, name) in code.scm_data">
              {{ name === 'scantrust_upload_batch' ? 'Upload Batch' : name }}: {{value}}
            </v-chip>
          </div>
          <v-spacer></v-spacer>
          <div class="d-flex flex-shrink-0 align-center">
            <div class="date mono mr-2">{{code.activated_at ? formatDate(code.activated_at) : formatDate(code.creation_date) }}</div>
            <code-tag-editor :tags="scmFields" :codes="[code]" :campaign="selectedCampaign.id"></code-tag-editor>
            <code-change-product v-on:code-transfered="loadCodes" :codes="[code]"></code-change-product>
          </div>
        </div>
      </div>
      <div v-if="codesLoaded && !codes.length" class="pa-2">
        No codes found.
      </div>
      <div class="pagination-ctn mt-5 mb-4">
        <v-pagination v-show="codesLoaded && codes.length" class="flex-grow-0" color="primary" :total-visible="7" v-model="page" :length="nbPages">
        </v-pagination>
      </div>
    </div>

  </div>
</template>

<script>

import axios from 'axios'
import CodesSearch from '@/components/CodesSearch'
import CodesCSV from '@/components/CodesCSV'
import Download from '@/components/Download'
import { mapGetters, mapMutations } from 'vuex'
import CodeTagEditor from '@/components/CodeTagEditor'
import CodeChangeProduct from '@/components/CodeChangeProduct'
import Utils from '@/utils/utils'

export default {
  name: 'codes',
  computed: {
    ...mapGetters(['scmFieldLoaded', 'scmFields', 'selectedCampaign', 'activeCodesFilters', 'codes', 'filteredCodeCount', 'codesLoaded', 'prefix', 'filteredProductCount']),

    nbPages () {
      return Math.ceil(this.filteredCodeCount / this.pageSize)
    }
  },
  components: {
    'codes-csv': CodesCSV,
    CodesSearch,
    CodeTagEditor,
    CodeChangeProduct,
    Download
  },
  watch: {
    '$route.params' (newVal) {
      if (newVal && this.$route.name === 'codes') {
        if (!this.scmFieldLoaded) {
          this.$store.dispatch('loadScmFields', this.selectedCampaign.id)
        }
        if (newVal.openBulkCreation) {
          this.$refs['csv-add-codes'].open()
        }
        this.loadCodes()
      }
    },

    page (newVal) {
      if (newVal) {
        this.selectAllEnabled = false
        this.selectedCodesIndexes = []
        this.loadCodes(newVal - 1)
      }
    },
    selectedCodesIndexes (newVal) {
      this.selectedCodes = []
      newVal.forEach((index) => {
        this.selectedCodes.push(this.codes[index])
      })
    }
  },
  created () {
    if (!this.scmFieldLoaded) {
      this.$store.dispatch('loadScmFields', this.selectedCampaign.id)
    }
  },
  mounted () {
    if (this.$route.params && this.$route.params.openBulkCreation) {
      this.$refs['csv-add-codes'].open()
    }
  },
  data () {
    return {
      selectAllEnabled: false,
      page: 1,
      pageSize: 20,
      selectedCodes: [],
      selectedCodesIndexes: [],
      cancelToken: null
    }
  },
  methods: {
    ...mapMutations({
      removeActiveFilter: 'REMOVE_CODE_ACTIVE_FILTER'
    }),

    editActiveFilter (filter) {
      this.$refs.codeSearch.selectTag(filter, filter.searchValue || '')
    },

    formatDate (string) {
      return Utils.formatDate(string, true)
    },

    resetBulkSelection () {
      this.selectAllEnabled = false
      this.selectedCodesIndexes = []
    },

    toggleSelectAll () {
      this.selectAllEnabled = !this.selectAllEnabled
      this.selectedCodesIndexes = []
      if (this.selectAllEnabled) {
        this.codes.forEach((code, index) => {
          this.selectedCodesIndexes.push(index)
        })
      }
    },

    toggleSelectCode (index) {
      if (this.selectedCodesIndexes.indexOf(index) >= 0) {
        if (this.selectAllEnabled) {
          this.selectAllEnabled = false
        }
        this.selectedCodesIndexes.splice(this.selectedCodesIndexes.indexOf(index), 1)
      } else {
        this.selectedCodesIndexes.push(index)
      }
    },

    removeFilter (filter) {
      this.removeActiveFilter(filter)
      this.cancellableLoad({ campaign: this.selectedCampaign.id })
      this.resetBulkSelection()
    },

    cancellableLoad (params) {
      if (this.cancelToken && this.cancelToken.cancel) {
        this.cancelToken.cancel('Cancelling request')
      }

      this.cancelToken = axios.CancelToken.source()
      this.$store.dispatch('loadCodes', { ...params, cancelToken: this.cancelToken })
    },

    loadCodes (page) {
      this.cancellableLoad({ campaign: this.selectedCampaign.id, offset: page ? page * this.pageSize : 0 })
    },

    addProductFilter (product) {
      const filter = {
        name: 'Product',
        key: 'products',
        value: product.name,
        searchKey: 'products',
        searchValue: product.id
      }

      this.$store.dispatch('addCodeFilter', filter)
      this.cancellableLoad({ campaign: this.selectedCampaign.id })
    },

    addFilter (key, value) {
      const filter = {
        name: key,
        key: key,
        value: value,
        searchKey: `scm_${key}`,
        searchValue: value
      }

      this.$store.dispatch('addCodeFilter', filter)
      this.cancellableLoad({ campaign: this.selectedCampaign.id })
    }
  }
}

</script>

<style lang="sass" scoped>

@import '@/variables.sass'

.codes
  overflow: auto
  height: calc(100vh - 63px)
  @include xl
    margin-left: 14%
    padding-right: 13%

.code-list-ctn
  border-top: 1px solid rgba(0, 0, 0, 0.3)
  .code-line
    border-bottom: 1px solid rgba(33, 33, 33, 0.08)

.search-settings
  padding: 15px 0px
  .search-label
    font-size: 16px
    color: #222222
    opacity: 0.5
    text-transform: uppercase

.search-settings, .code-line
  .v-input--checkbox
    padding-top: 0 !important
    margin-top: 0 !important

.code-checkbox
  padding: 10px
  border-bottom: 1px solid rgba(33,33,33,0.08)

.url
  color: #333
  font-style: italic
  font-size: 14px

.product-name
  cursor: pointer

.pagination-ctn
  text-align: right

.date
  color: #999999

</style>
