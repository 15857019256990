<template>
  <div>
    <v-btn text color="primary" @click="dialog = true"><v-icon class="mr-2">$csv</v-icon>create more</v-btn>
    <v-dialog width="900" v-model="dialog">
      <div class="title d-flex align-center">
        <div>Create More QR Codes by CSV</div>
        <info-tooltip class="ml-3" position="bottom" content="This feature will create 1 code for each product listed in your CSV file. It will also apply tag values if specified."></info-tooltip>
      </div>
      <v-stepper :value="step">
        <v-stepper-header>
          <v-stepper-step step="1">Prepare</v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step step="2">Batch ID</v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step step="3">Upload</v-stepper-step>
        </v-stepper-header>

        <v-stepper-items>
          <v-stepper-content step="1">
            <div class="content">
              <!-- <div class="infos font-weight-medium"><br><br></div> -->
              <div class="important font-weight-bold">Your CSV header must contain:</div>
              <v-layout>
                <v-flex class="infos font-weight-medium pr-2">
                  - sku<br><br>

                  Other columns supported:<br>
                  - tags keys
                  <info-tooltip content="Download the CSV template below in order to get the full list of available tags"></info-tooltip>
                 <br><br>
                  This feature will create 1 QR code per line in the CSV.
                  Use comma (,) as the delimiter.
                </v-flex>
                <v-flex>
                  <div class="file-upload-ctn">
                    <v-layout @click="clickOnFileInput($event, 'csv-file')" class="upload-btn" align-center justify-center column>
                      <div v-if="!csv || !csv.name" class="text-xs-center">
                        <v-icon x-large color="primary">mdi-cloud-upload</v-icon>
                        <div class="text-uppercase font-weight-bold">Choose file</div>
                        <div class="format-hint">
                          Supported formats:<br>
                          CSV
                        </div>
                      </div>

                      <div v-if="csv && csv.name" class="text-xs-center">
                        <v-icon x-large color="primary">mdi-cloud-upload</v-icon>
                        <div class="text-uppercase font-weight-bold">Change File</div>
                        <div class="format-hint">
                          {{csv.name}}
                        </div>
                      </div>

                    </v-layout>
                    <input v-show="false" accept=".csv" @change="onFileChange($event, csv)" type="file" ref="csv-file" />
                  </div>
                </v-flex>
              </v-layout>
            </div>
            <v-layout class="footer">
              <v-flex grow>
                <v-btn @click="downloadCSVTemplate()" text color="primary">Download CSV Template<v-icon class="ml-2">mdi-cloud-download</v-icon></v-btn>
              </v-flex>
              <v-flex shrink>
                <v-btn @click="close()" text color="primary">Cancel</v-btn>
                <v-btn @click="goToBatchStep()" raised color="primary">Next</v-btn>
              </v-flex>
            </v-layout>
          </v-stepper-content>

          <v-stepper-content step="2">
            <div class="important font-weight-bold">Upload Batch (optional):</div>
            <v-layout class="content">
              <v-flex class="infos font-weight-medium">
                Label this upload batch so you can easily filter these codes later. You can also download this batch after the uploading is complete. Enter something such as today’s date or simply “Batch Number 123”.
                <v-text-field class="mt-3 mb-6" label="Upload Batch Label" counter="50" :rules="batchRules" v-model="batchName"></v-text-field>
              </v-flex>
            </v-layout>
            <v-layout class="footer">
              <v-spacer></v-spacer>
              <v-flex shrink>
                <v-btn @click="step = 1" text color="primary">Back</v-btn>
                <v-btn @click="parseCSV()" raised color="primary">Next</v-btn>
              </v-flex>
            </v-layout>
          </v-stepper-content>

          <v-stepper-content class="custom-stepper" step="3">
            <div class="loading" v-show="!completed">
              <v-progress-linear v-show="!completed" :indeterminate="true"></v-progress-linear>
              <v-layout align-center justify-center row fill-height>
                <v-flex shrink class="text-center infos font-weight-bold">Uploading... ({{this.uploadCount}} / {{this.uploadData.length}})<br>
                <div v-if="this.throttling">(Upload in progress, do not close this window)</div>
                </v-flex>
              </v-layout>
            </div>

            <div v-if="completed && uploadErrors.length" class="content">
              <div class="important font-weight-bold" >Warning:</div>

              <div v-if="!quotaError" class="infos font-weight-medium">Something is wrong with your CSV. Please fix the issues below and try again:<br><br></div>
              <div v-for="(line, index) in uploadErrors" :key="`errors-${index}`" class="infos font-weight-medium">
                {{line}}
              </div>
              <div v-if="hasWarning && !quotaError" class="infos mt-5 font-weight-medium">If you still want to proceed with the upload, these lines will be excluded.<br><br></div>
              <v-btn class="mt-5" v-if="hasWarning" @click="forceUpload()" raised color="primary">Upload anyways</v-btn>
            </div>

            <div v-if="completed && !uploadErrors.length" class="content">
              <div class="important font-weight-bold">Upload Complete!</div>

              <div v-if="createCount > 0" class="infos font-weight-medium">{{this.createCount}} Codes(s) have been created.</div>
            </div>
            <v-layout class="footer" row>
              <v-spacer></v-spacer>
              <v-btn v-if="completed && uploadErrors.length" @click="step = 1" text color="primary">Back</v-btn>
              <v-btn @click="close(true)" text color="primary">Close</v-btn>
              <v-btn v-if="completed && !uploadErrors.length && this.batchName" @click="addBatchFilter" raised color="primary">download this batch</v-btn>
            </v-layout>
          </v-stepper-content>
        </v-stepper-items>

      </v-stepper>
    </v-dialog>
  </div>
</template>

<script>

import InfoTooltip from '@/components/utils/InfoTooltip.vue'
import * as Papa from 'papaparse'
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'CodesCSV',
  components: {
    InfoTooltip
  },
  data () {
    return {
      dialog: false,
      step: 1,
      headerLine: 'sku',
      mandatoryHeader: ['sku'],
      uploadErrors: [],
      quotaError: false,
      uploadData: [],
      uploadCount: 0,
      updateCount: 0,
      createCount: 0,
      hasWarning: false,
      throttling: false,
      csv: {},
      line: 0,
      completed: false,
      batchSize: 10,
      batchName: '',
      batchRules: [
        v => v.length <= 50 || 'Batch number cannot exceed 50 characters'
      ]
    }
  },
  computed: {
    ...mapGetters(['defaultBrand', 'selectedCampaign', 'scmFields', 'inactiveCodesLoaded', 'totalActiveCodesCount', 'inactiveCodes', 'totalCodes'])
  },
  methods: {

    ...mapMutations({
      clearFilters: 'CLEAR_ACTIVE_CODE_FILTERS',
      setTotalActiveCount: 'SET_TOTAL_ACTIVE_CODES_COUNT'
    }),

    open () {
      this.dialog = true
    },

    close (doReload) {
      if (doReload) {
        this.$store.dispatch('loadCodes', { campaign: this.selectedCampaign.id })
        this.$emit('codes-activated')
      }
      this.batchName = ''
      this.resetState()
      this.dialog = false
    },

    addBatchFilter () {
      this.clearFilters()
      const filter = {
        name: 'Upload Batch',
        key: 'scantrust_upload_batch',
        value: this.batchName,
        searchKey: 'scm_scantrust_upload_batch',
        searchValue: this.batchName
      }

      this.$store.dispatch('addCodeFilter', filter)
      this.$store.dispatch('loadCodes', { campaign: this.selectedCampaign.id })
      this.$emit('codes-activated')
      this.close()
    },

    goToBatchStep () {
      if (!this.csv || !this.csv.name) {
        this.clickOnFileInput(null, 'csv-file')
        return
      }
      this.step = 2
    },

    resetState () {
      this.step = 1
      this.uploadErrors = []
      this.uploadData = []
      this.line = 0
      this.csv = {}
      this.createCount = 0
      this.uploadCount = 0
      this.completed = false
      this.quotaError = false
      this.$refs['csv-file'].value = ''
    },

    uploadBatch (index, isRetry) {
      const promiseExistsArray = []
      const promiseUpdateCodesArray = []

      // Take next BatchSize Products
      let array = JSON.parse(JSON.stringify(this.uploadData))
      array = array.splice(index, this.batchSize)

      if (!array.length) {
        this.completed = true
        this.setTotalActiveCount(this.totalActiveCodesCount + this.createCount)
        return
      }

      array.forEach((product) => {
        promiseExistsArray.push(this.$store.dispatch('loadProductBySKU', product))
      })

      Promise.all(promiseExistsArray).then(async (res) => {
        if (this.inactiveCodes.length < promiseExistsArray.length) {
          await this.$store.dispatch('loadInactiveCodes', { campaign: this.selectedCampaign.id })
          if (!this.inactiveCodes.length) {
            this.completed = true
            this.quotaError = true
            this.uploadErrors.push('Maximum number of codes reached. Contact support to increase the number of codes available.')
            this.setTotalActiveCount(this.totalActiveCodesCount + this.createCount)
          }
        }
        res.forEach((product, promiseIndex) => {
          if (product && product.id) {
            // Product exist, activate code + set tags
            const tags = JSON.parse(JSON.stringify(array[promiseIndex]))
            if (tags.sku) {
              delete tags.sku
            }

            if (this.batchName) {
              tags.scantrust_upload_batch = this.batchName
            }

            if (!isRetry) {
              this.createCount++
            }
            promiseUpdateCodesArray.push(this.$store.dispatch('activateAndAssignCode', { product: product, tags, index: promiseIndex }))
          } else {
            this.uploadErrors.push(`Line ${index + promiseIndex + 2}: no product found with sku ${array[promiseIndex].sku} in your company. Make sure you create this product first before creating codes.`)
          }
        })

        Promise.all(promiseUpdateCodesArray).then(async (res) => {
          this.uploadCount += promiseUpdateCodesArray.length
          this.uploadBatch(index + this.batchSize)
        }).catch((err) => {
          if (err && err.response && err.response.status === 429) {
            this.throttling = true
            console.log('Throttling')
            setTimeout(() => {
              this.uploadBatch(index, true)
            }, 10000)
          }
        })
      }).catch((err) => {
        if (err && err.response && err.response.status === 429) {
          this.throttling = true
          console.log('Throttling')
          setTimeout(() => {
            this.uploadBatch(index, true)
          }, 10000)
        }
      })
    },

    async startUpload () {
      this.uploadBatch(0)
    },

    isHeaderValid (fields) {
      const missingFields = JSON.parse(JSON.stringify(this.mandatoryHeader))
      this.mandatoryHeader.forEach((mandatoryField) => {
        const found = fields.find((field) => {
          return field === mandatoryField
        })

        if (found) {
          missingFields.splice(missingFields.indexOf(mandatoryField), 1)
        }
      })

      if (missingFields.length) {
        this.uploadErrors.push('Line: 1 Invalid file header.')
        this.uploadErrors.push('Missing fields: ' + missingFields.join(','))
      }
      return missingFields.length === 0
    },

    parseCSV () {
      // if (!this.csv.name) {
      //   this.$store.dispatch('showSnackBar', { message: 'Please select a CSV file' })
      //   return
      // }
      this.step = 3
      Papa.parse(this.csv, {
        skipEmptyLines: true,
        fastMode: true,
        header: true,
        step: (row, parser) => {
          let rowHasError = false
          if (this.line === 0 && !this.isHeaderValid(row.meta.fields)) {
            parser.abort()
          }

          if (row.errors.length) {
            row.errors.some((err) => {
              this.uploadErrors.push(`Line ${err.row + 1}: ${err.message}`)
              parser.abort()
            })
          }

          for (var key in row.data) {
            if ((key === 'sku' || key === 'name') && !row.data[key]) {
              this.uploadErrors.push(`Line ${this.line + 2}: No value found for column ${key}`)
              rowHasError = true
              // parser.abort()
            }
          }

          if (!rowHasError) {
            this.uploadData.push(row.data)
          } else {
            this.hasWarning = true
          }
          this.line++
        },
        complete: (results) => {
          this.quotaError = false
          if (results.meta.aborted) {
            this.completed = true
          } else {
            if (this.totalCodes - this.totalActiveCodesCount < this.line) {
              this.hasWarning = true
              this.quotaError = true
              this.uploadErrors.push(`You only have ${this.totalCodes - this.totalActiveCodesCount} codes available. If you continue, only the codes for the this first ${this.totalCodes - this.totalActiveCodesCount} lines of your CSV will be created.`)
            }
            if (this.hasWarning) {
              this.completed = true
            } else if (this.uploadData.length) {
              console.log('starting upload')
              this.startUpload()
            } else {
              this.uploadErrors.push('This file is empty.')
              this.completed = true
            }
          }
        }
      })
    },

    forceUpload () {
      this.completed = false
      this.hasWarning = false
      this.quotaError = false
      this.uploadErrors = []
      this.startUpload()
    },

    downloadCSVTemplate () {
      let csvContent = 'data:text/csv;charset=utf-8,'
      csvContent += this.headerLine
      this.scmFields.forEach((tag) => {
        if (tag.key !== 'scantrust_upload_batch') {
          csvContent += `,${tag.key}`
        }
      })
      const data = encodeURI(csvContent)
      const link = document.createElement('a')
      link.setAttribute('href', data)
      link.setAttribute('download', 'codes_generation_template.csv')
      link.click()
    },

    clickOnFileInput (event, inputName) {
      if (event) {
        event.stopImmediatePropagation()
      }
      this.$refs[inputName].click()
    },

    onFileChange (event) {
      setTimeout(() => {
        this.csv = event.target.files[0]
      })
    }
  },
  created () {
    if (!this.inactiveCodesLoaded) {
      this.$store.dispatch('loadInactiveCodes', { campaign: this.selectedCampaign.id })
    }
  }
}

</script>

<style lang="sass" scoped>

.title
  padding: 25px
  background: white
  position: relative
  z-index: 99

.link
  color: #0789b2
  text-decoration: underline
  cursor: pointer

.v-stepper
  position: relative
  top: -1px
  border-radius: 0px

.important
  font-size: 19px
  margin-bottom: 15px

.infos
  color: rgba(0,0,0,0.6)
  font-size: 14px

.content
  padding-bottom: 40px

.upload-btn
  text-align: center
  width: 100%
  height: 100%
  background: #f1f1f1
  padding: 15px
  cursor: pointer
  .v-icon
    width: 35px

  .format-hint
    word-break: break-all
    margin-top: 15px

  .text-uppercase
    color: #00aeef
    font-size: 14px

.custom-stepper
  padding: 0px
  .v-progress-linear
    margin-top: 0px
  .footer
    margin: 0px 24px 16px 24px
  .content
    padding: 24px 16px
  .loading
    .infos
      padding: 50px 0px
      color: #00aeef
      font-size: 20px
      opacity: 1

.file-upload-ctn
  min-width: 250px

</style>
