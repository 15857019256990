<template>
  <div class="codes-search">
    <v-menu
      bottom
      v-model="searchMenu"
      full-width
      :close-on-content-click="false"
    >
      <template v-slot:activator="{ on, attrs }">
        <button
          class="open-search-btn"
          color="primary"
          dark
          v-bind="attrs"
          v-on="on"
          @click="open()"
        >
          <div class="d-flex">
            Filter QR Codes
            <v-spacer></v-spacer>
            <v-icon>mdi-magnify</v-icon>
          </div>
        </button>
      </template>

      <div class="search-input">
        <v-form autocomplete="off" v-on:submit.prevent="initSearch">
          <v-text-field
            v-model="searchText"
            :label="searchLabel"
            ref="code-search-input"
            solo
            shaped
            hide-details
            autocomplete="off"
            append-icon="mdi-magnify"
            @keyup.delete="onDelete"
            @keyup.up="onArrowUp"
            @keyup.down="onArrowDown">
            <template v-slot:prepend-inner>
              <div v-if="selectedTag.name">
                <!-- <v-chip text-color="#0789b2" close @click:close="removeSelectedTag">{{selectedTag.name}}:</v-chip> -->
                <v-chip text-color="#0789b2">{{selectedTag.name}}:</v-chip>
              </div>
            </template>
          </v-text-field>
        </v-form>
      </div>
      <div class="search-results-ctn">
        <div v-if="!selectedTag.name">
          <div v-if="filteredScmFields.length > 0">
            <div class="search-hint">narrow your search:</div>
            <div class="tags-ctn">
              <v-chip @click="selectTag(tag)" class="tag ml-2 my-1" :class="{ 'focussed': focussedIndex == index }" text-color="#0789b2" :key="tag.key" v-for="(tag, index) in filteredScmFields">{{tag.name}}</v-chip>
            </div>
          </div>
          <div v-if="filteredProducts.length > 0">
            <div class="search-hint">Products:</div>
            <div class="tags-ctn">
              <v-chip @click="setProductFilter(product)" class="tag ml-2 my-1" :class="{ 'focussed': focussedIndex === filteredScmFields.length + index}" text-color="#0789b2" :key="product.id" v-for="(product, index) in filteredProducts">{{product.name}} - {{product.sku}}</v-chip>
            </div>
          </div>
        </div>

        <div v-if="selectedTag.name || (filteredProducts.length === 0 && filteredScmFields.length === 0)" class="search-hint">
          Press enter to search
        </div>

      </div>
    </v-menu>
  </div>
</template>

<script>

import { mapGetters } from 'vuex'
import utils from '@/utils/utils'
import productService from '@/services/products'

export default {
  name: 'CodesSearch',
  props: ['campaign'],
  computed: {
    ...mapGetters(['activeScmFields', 'activeCodesFilters', 'products']),

    searchLabel () {
      if (this.selectedTag && this.selectedTag.name) {
        return `Search ${this.selectedTag.name}`
      } else {
        return 'Filter QR Codes'
      }
    },

    filteredScmFields () {
      if (!this.activeScmFields) {
        return []
      }
      return this.activeScmFields.filter((field) => {
        return (field.name.toLowerCase().indexOf(this.searchText.toLowerCase()) > -1 || field.key.toLowerCase().indexOf(this.searchText.toLowerCase()) > -1)
      })
    }
  },
  data () {
    return {
      searchMenu: false,
      debounceSearch: null,
      searchText: '',
      previousSearchText: '',
      filteredProducts: [],
      selectedTag: {},
      focussedIndex: -1
    }
  },
  watch: {
    searchText (newVal, oldVal) {
      if (newVal.length) {
        this.previousSearchText = oldVal
      }
      this.filteredProducts = this.filteredProducts.filter((p) => {
        return p.name.toLowerCase().includes(newVal.toLowerCase()) ||
          p.sku.toLowerCase().includes(newVal.toLowerCase())
      })
      if (!this.selectedTag.name) {
        this.debounceSearch()
      }
    },
    async campaign () {
      await this.searchProducts()
      // this.initSearch()
    }
  },
  methods: {

    async searchProducts () {
      const { results } = await productService.loadProducts({ search: this.searchText, campaign: this.campaign })
      this.filteredProducts = results
    },

    onDelete () {
      setTimeout(() => {
        if (this.previousSearchText.length > this.searchText.length) {
          this.previousSearchText = this.searchText
        } else if (this.previousSearchText.length <= this.searchText.length && this.selectedTag.name) {
          this.removeSelectedTag()
          this.focus()
        }
      })
    },

    onArrowUp (event) {
      event.preventDefault()
      if (this.focussedIndex > 0) {
        this.focussedIndex--
      }
    },

    onArrowDown (event) {
      event.preventDefault()
      let minus = 0

      if (this.filteredScmFields.length) {
        minus++
      }

      if (this.filteredProducts.length) {
        minus++
      }

      if (this.focussedIndex <= this.filteredScmFields.length + this.filteredProducts.length - minus) {
        this.focussedIndex++
      } else {
        this.focussedIndex = 0
      }
    },

    close () {
      this.selectedTag = {}
      this.searchText = ''
      this.searchMenu = false
    },

    open () {
      this.searchMenu = true
      this.focus()
    },

    focus () {
      setTimeout(() => {
        if (this.$refs['code-search-input']) {
          this.$refs['code-search-input'].focus()
          this.focussedIndex = -1
        }
      }, 100)
    },

    initSearch () {
      if (this.focussedIndex >= 0) {
        if (this.focussedIndex < this.filteredScmFields.length) {
          this.selectTag(this.filteredScmFields[this.focussedIndex])
        } else {
          this.setProductFilter(this.filteredProducts[this.focussedIndex - this.filteredScmFields.length])
        }
        return
      }

      if (this.filteredProducts.length + this.filteredScmFields.length === 1) {
        if (this.filteredScmFields.length === 1) {
          this.selectTag(this.filteredScmFields[0])
        } else {
          this.setProductFilter(this.filteredProducts[0])
        }
        return
      }

      // If Tag selected, we create filter for scm field
      if (this.selectedTag.name) {
        const filter = {
          name: this.selectedTag.name,
          key: this.selectedTag.key,
          value: this.searchText,
          searchKey: `scm_${this.selectedTag.key}`,
          searchValue: this.searchText
        }
        this.$store.dispatch('addCodeFilter', filter)
      } else if (this.searchText) {
        const filter = {
          name: 'Code ID',
          key: 'extended_id',
          value: this.searchText,
          searchKey: 'extended_id',
          searchValue: this.searchText
        }
        this.$store.dispatch('addCodeFilter', filter)
      }
      this.$store.dispatch('loadCodes', { campaign: this.campaign })
      this.close()
    },

    setProductFilter (product) {
      if (product && product.id) {
        const filter = {
          name: 'Product',
          key: 'products',
          value: product.name,
          searchKey: 'products',
          searchValue: product.id
        }
        this.$store.dispatch('addCodeFilter', filter)
        this.$store.dispatch('loadCodes', { campaign: this.campaign })
      }
      this.close()
    },

    selectTag (tag, search) {
      this.searchText = search || ''
      this.previousSearchText = search || ''
      this.selectedTag = tag

      if (typeof search === 'string') {
        this.open()
      } else {
        this.focus()
      }
    },

    removeSelectedTag () {
      this.selectedTag = {}
    }
  },
  created () {
    this.debounceSearch = utils.debounce(() => {
      this.searchProducts()
    }, 400, true)

    this.filteredProducts = this.products
    this.initSearch()
  }
}

</script>

<style lang="sass">

.codes-search
  position: relative
  .open-search-btn
    width: 100%
    padding: 7px 20px
    color: #999999
    text-align: left
    border-radius: 5px
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2)

.search-input
  width: 100%
  .v-input
    .v-input__control
      .v-input__slot
        box-shadow: none !important
        border-bottom: 1px solid rgba(0,0,0,0.4)

.search-results-ctn
  background: white
  z-index: 99
  padding: 10px

  .search-hint
    letter-spacing: 0.8px
    font-size: 12px
    padding: 10px 10px
    color: #333333
    font-family: 'Roboto'
    text-transform: uppercase

  .focussed
    background: #0789b2 !important
    color: white !important

</style>
