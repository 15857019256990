<template>
  <div class='iccube'>
    <div v-if="errorMessage" class="alert">
      {{ errorMessage }}
    </div>
    <div v-if="loading" class="loading">
      <div class="lds-ring">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
    <div id="is3-dashboard"></div>
  </div>
</template>

<script>
import { DashboardsLoaderDivContext } from '@ic3/reporting-api-embedded'
import { mapGetters } from 'vuex'
import Api from '@/api'

export default {
  name: 'iccube',
  data () {
    return {
      ic3reporting: null,
      errorMessage: '',
      reportName: '',
      loading: true
    }
  },
  computed: {
    ...mapGetters(['scantrustAuthToken'])
  },
  created () {
    console.log('query', this.$route.query)
    this.reportName = this.$route.query.report ? `shared:/${this.$route.query.report}` : 'shared:/billing/Billing Dashboard (Official)'
    console.log('report name', this.reportName)
  },
  mounted () {
    this.initEventHandler()
    this.initIccube()

    console.log('parent', window.parent)
  },
  methods: {
    openDashboard (path) {
      if (this.ic3reporting != null) {
        this.ic3reporting.openReport({
          path: path,
          onDefinition: (report) => {
            console.log('[ic3-demo] open-report:' + report.getPath() + ' - onDefinition', report)
            this.loading = false
          },
          onError: (error) => {
            console.log('[ic3-demo] open-report: ' + path + ' - onError', error)
            this.errorMessage = error
            this.loading = false
            return true
          }
        })
      }
    },
    initIccube () {
      console.log('init iccube')
      this.loading = true

      // div connect
      const urlHome = Api.iccube_url
      const context = new DashboardsLoaderDivContext({ customHeaders: 'dashboards', urlHome: urlHome })
      const container = document.getElementById('is3-dashboard')

      if (container == null) {
        this.errorMessage = 'container not found'
        return
      }
      // load dashboard
      const options = {
        uid: 'container1',
        container: container
      }
      context.loadLibsAndInitialize(options).then((reporting) => {
        console.log('ic3ready : ', reporting)
        this.ic3reporting = reporting

        this.openDashboard(this.reportName)
      }).catch((error) => {
        this.loading = false
        this.errorMessage = error
        console.error(error)
      })
    },

    // requestToken () {
    //   parent.postMessage({ type: 'jwt-request' }, '*')
    // },

    initEventHandler () {
      const token = this.scantrustAuthToken
      const eventMethod = window.addEventListener
        ? 'addEventListener'
        : 'attachEvent'
      const eventer = window[eventMethod]
      const messageEvent = eventMethod === 'attachEvent'
        ? 'onmessage'
        : 'message'

      eventer(messageEvent, (e) => {
        const data = e.data
        console.log('test', data.type)

        if (data.type === 'ic3-custom-headers-request') {
          const embeddedDiv = (data.ic3callerType === 'div')
          console.log('test', token)
          const target = !embeddedDiv ? document.getElementById('ic3-iframe') && document.getElementById('ic3-iframe')?.contentWindow : window
          target.postMessage({
            type: 'ic3-custom-headers-reply',
            data: {
              headers: {
                Authorization: `JWT ${token}`
              }
            }
          }, '*')
        }
      })
    }
  }
}

</script>

<style lang='sass' scoped>

#is3-dashboard
  height: 100vh
  width: 100%

.loading
    position: absolute
    top: 0
    bottom: 0
    left: 0
    right: 0
    margin: auto
    z-index: 9999
    background: rgba(white, 0.8)
    display: flex
    align-content: center
    justify-content: center
    align-items: center

.lds-ring
  display: inline-block
  position: relative
  width: 80px
  height: 80px
  div
    box-sizing: border-box
    display: block
    position: absolute
    width: 64px
    height: 64px
    margin: 8px
    border: 8px solid #15bbf0
    border-radius: 50%
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite
    border-color: #15bbf0 transparent transparent transparent
    &:nth-child(1)
      animation-delay: -0.45s
    &:nth-child(2)
      animation-delay: -0.3s
    &:nth-child(3)
      animation-delay: -0.15s

@keyframes lds-ring
  0%
    transform: rotate(0deg)
  100%
    transform: rotate(360deg)
</style>
