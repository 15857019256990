import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VStepper } from 'vuetify/lib/components/VStepper';
import { VStepperContent } from 'vuetify/lib/components/VStepper';
import { VStepperHeader } from 'vuetify/lib/components/VStepper';
import { VStepperItems } from 'vuetify/lib/components/VStepper';
import { VStepperStep } from 'vuetify/lib/components/VStepper';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {
  var _vm$recap, _vm$settings$template, _vm$settings$template2, _vm$settings$template3, _vm$recap2, _vm$recap3, _vm$recap4, _vm$recap5, _vm$recap6, _vm$recap7, _vm$recap8, _vm$recap8$productsNo, _vm$recap9, _vm$recap9$productsNo, _vm$recap10, _vm$recap12, _vm$recap13, _vm$recap14, _vm$recap15, _vm$recap16, _vm$recap17;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "bulk-upload"
  }, [_c('div', {
    staticClass: "d-flex align-center pt-5 pb-3"
  }, [_c('router-link', {
    attrs: {
      "to": "/products"
    }
  }, [_c(VBtn, {
    staticClass: "ml-3",
    attrs: {
      "icon": ""
    }
  }, [_c(VIcon, {
    attrs: {
      "color": "black"
    }
  }, [_vm._v("mdi-arrow-left")])], 1)], 1), _c('div', {
    staticClass: "page-title mono pl-3"
  }, [_vm._v(_vm._s(_vm.$t('bulk_upload_title')))]), _c(VSpacer)], 1), _c('div', {
    staticClass: "bulk-row"
  }, [_c(VStepper, {
    staticClass: "bulk-upload-ctn",
    attrs: {
      "value": _vm.step
    }
  }, [_c(VStepperHeader, [_c(VStepperStep, {
    attrs: {
      "step": "1"
    }
  }, [_vm._v(_vm._s(_vm.$t('bulk_upload_step_1')) + " ")]), _c(VDivider), _c(VStepperStep, {
    attrs: {
      "step": "2"
    }
  }, [_vm._v(_vm._s(_vm.$t('bulk_upload_step_2')))]), _c(VDivider), _c(VStepperStep, {
    attrs: {
      "step": "3"
    }
  }, [_vm._v(_vm._s(_vm.$t('bulk_upload_step_3')))])], 1), _c(VStepperItems, [_c(VStepperContent, {
    attrs: {
      "step": "1"
    }
  }, [_c('div', {
    staticClass: "row ma-2"
  }, [_c(VCol, {
    staticClass: "infos font-weight-medium"
  }, [_c('div', {
    staticClass: "title"
  }, [_vm._v(_vm._s(_vm.$t('bulk_upload_select_category')))]), _c('div', {
    staticClass: "hint"
  }, [_vm._v(_vm._s(_vm.$t('bulk_upload_select_category_hint')))]), _c(VRadioGroup, {
    attrs: {
      "row": ""
    },
    model: {
      value: _vm.settings.selectedTemplate,
      callback: function callback($$v) {
        _vm.$set(_vm.settings, "selectedTemplate", $$v);
      },
      expression: "settings.selectedTemplate"
    }
  }, [_vm._l(_vm.templates, function (template) {
    return _c(VRadio, {
      key: template.key,
      attrs: {
        "label": _vm.$t("bulk_upload_".concat(template.key)),
        "value": template
      }
    });
  }), _c(VRadio, {
    staticClass: "py-2",
    attrs: {
      "label": _vm.$t('bulk_upload_template_copy'),
      "value": "copy"
    }
  })], 2), _vm.settings.selectedTemplate === 'copy' ? _c(VAutocomplete, {
    attrs: {
      "solo": "",
      "placeholder": _vm.$t('bulk_upload_template_search_product'),
      "items": _vm.filteredProducts,
      "loading": _vm.isLoadingProduct,
      "no-filter": "",
      "search-input": _vm.searchProduct,
      "return-object": ""
    },
    on: {
      "update:searchInput": function updateSearchInput($event) {
        _vm.searchProduct = $event;
      },
      "update:search-input": function updateSearchInput($event) {
        _vm.searchProduct = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "selection",
      fn: function fn(data) {
        return [_c('div', _vm._b({}, 'div', data.attrs, false), [_vm._v(" " + _vm._s(data.item.name) + " - " + _vm._s(data.item.sku) + " ")])];
      }
    }, {
      key: "item",
      fn: function fn(data) {
        return [_c('div', _vm._b({}, 'div', data.attrs, false), [_vm._v(" " + _vm._s(data.item.name) + " - " + _vm._s(data.item.sku) + " ")])];
      }
    }], null, false, 198270716),
    model: {
      value: _vm.settings.templateProduct,
      callback: function callback($$v) {
        _vm.$set(_vm.settings, "templateProduct", $$v);
      },
      expression: "settings.templateProduct"
    }
  }) : _vm._e(), _vm.settings.selectedTemplate !== 'copy' ? _c('div', [_c('div', {
    staticClass: "title"
  }, [_vm._v(_vm._s(_vm.$t('bulk_upload_reference_language')))]), _c('div', {
    staticClass: "my-2 hint"
  }, [_vm._v(_vm._s(_vm.$t('bulk_upload_reference_language_hint')))]), _c(VAutocomplete, {
    staticClass: "mt-2",
    attrs: {
      "solo": "",
      "items": _vm.languages,
      "item-value": "key",
      "item-text": "description"
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function fn(_ref) {
        var item = _ref.item,
          on = _ref.on,
          attrs = _ref.attrs;
        return [_c(VListItem, _vm._g(_vm._b({
          directives: [{
            name: "show",
            rawName: "v-show",
            value: item.key != 'all',
            expression: "item.key != 'all'"
          }]
        }, 'v-list-item', attrs, false), on), [_c(VListItemContent, [_c(VListItemTitle, [_vm._v(" " + _vm._s(item.description) + " ")])], 1)], 1)];
      }
    }], null, false, 558046590),
    model: {
      value: _vm.settings.defaultLanguage,
      callback: function callback($$v) {
        _vm.$set(_vm.settings, "defaultLanguage", $$v);
      },
      expression: "settings.defaultLanguage"
    }
  }), _c('div', {
    staticClass: "title"
  }, [_vm._v(_vm._s(_vm.$t('bulk_upload_available_languages')))]), _c('div', {
    staticClass: "my-2 hint"
  }, [_vm._v(_vm._s(_vm.$t('bulk_upload_available_languages_hint')))]), _c(VAutocomplete, {
    staticClass: "mt-2",
    attrs: {
      "solo": "",
      "search-input": _vm.searchLanguages,
      "multiple": "",
      "items": _vm.languages,
      "item-value": "key",
      "item-text": "description"
    },
    on: {
      "update:searchInput": function updateSearchInput($event) {
        _vm.searchLanguages = $event;
      },
      "update:search-input": function updateSearchInput($event) {
        _vm.searchLanguages = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function fn(_ref2) {
        var item = _ref2.item,
          on = _ref2.on,
          attrs = _ref2.attrs;
        return [_c(VListItem, _vm._g(_vm._b({}, 'v-list-item', attrs, false), on), [_c(VCheckbox, {
          model: {
            value: attrs.inputValue,
            callback: function callback($$v) {
              _vm.$set(attrs, "inputValue", $$v);
            },
            expression: "attrs.inputValue"
          }
        }), _c(VListItemContent, [_c(VListItemTitle, [_vm._v(" " + _vm._s(item.description) + " ")])], 1)], 1)];
      }
    }], null, false, 3203360191),
    model: {
      value: _vm.settings.availableLanguages,
      callback: function callback($$v) {
        _vm.$set(_vm.settings, "availableLanguages", $$v);
      },
      expression: "settings.availableLanguages"
    }
  })], 1) : _vm._e(), _c(VBtn, {
    attrs: {
      "text": "",
      "color": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.downloadTemplate();
      }
    }
  }, [_c(VIcon, {
    staticClass: "mr-2"
  }, [_vm._v("mdi-cloud-download")]), _vm._v(_vm._s(_vm.$t('bulk_upload_download_template')))], 1), _c('div', {
    staticClass: "my-2 hint"
  }, [_vm._v(_vm._s(_vm.$t('bulk_upload_download_template_hint')))])], 1), _c(VCol, {
    attrs: {
      "cols": "5"
    }
  }, [_c('div', {
    staticClass: "file-upload-ctn ml-5"
  }, [_c(VLayout, {
    staticClass: "upload-btn",
    attrs: {
      "align-center": "",
      "justify-center": "",
      "column": ""
    },
    on: {
      "click": function click($event) {
        return _vm.clickOnFileInput($event, 'xls-file-input');
      }
    }
  }, [!_vm.file || !_vm.file.name ? _c('div', {
    staticClass: "text-xs-center"
  }, [_c(VIcon, {
    attrs: {
      "x-large": "",
      "color": "primary"
    }
  }, [_vm._v("mdi-cloud-upload")]), _c('div', {
    staticClass: "text-uppercase font-weight-bold"
  }, [_vm._v(_vm._s(_vm.$t('bulk_upload_chose_file')))]), _c('div', {
    staticClass: "format-hint"
  }, [_vm._v(" " + _vm._s(_vm.$t('bulk_upload_supported_formats')) + ":"), _c('br'), _vm._v(" .xls,.xlsx ")])], 1) : _vm._e(), _vm.file && _vm.file.name ? _c('div', {
    staticClass: "text-xs-center"
  }, [_c(VIcon, {
    attrs: {
      "x-large": "",
      "color": "primary"
    }
  }, [_vm._v("mdi-cloud-upload")]), _c('div', {
    staticClass: "text-uppercase font-weight-bold"
  }, [_vm._v(_vm._s(_vm.$t('csv_change_file')))]), _c('div', {
    staticClass: "format-hint"
  }, [_vm._v(" " + _vm._s(_vm.file.name) + " ")])], 1) : _vm._e()]), _c('input', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: false,
      expression: "false"
    }],
    ref: "xls-file-input",
    attrs: {
      "accept": ".xls,.xlsx",
      "type": "file"
    },
    on: {
      "change": function change($event) {
        return _vm.onFileChange($event);
      }
    }
  }), _c('div', {
    staticClass: "instructions-ctn py-2 px-5"
  }, [_c('div', {
    staticClass: "instructions-title font-weight-bold"
  }, [_vm._v(_vm._s(_vm.$t('bulk_upload_how_to_use')))]), _c('ul', [_c('li', {
    staticClass: "hint"
  }, [_vm._v(_vm._s(_vm.$t('bulk_upload_instructions_1')))]), _c('li', {
    staticClass: "hint"
  }, [_vm._v(_vm._s(_vm.$t('bulk_upload_instructions_2')))]), _c('li', {
    staticClass: "hint"
  }, [_vm._v(_vm._s(_vm.$t('bulk_upload_instructions_3')))]), _c('li', {
    staticClass: "hint"
  }, [_vm._v(_vm._s(_vm.$t('bulk_upload_instructions_4')))]), _c('li', {
    staticClass: "hint"
  }, [_vm._v(_vm._s(_vm.$t('bulk_upload_instructions_5')))]), _c('li', {
    staticClass: "hint"
  }, [_vm._v(_vm._s(_vm.$t('bulk_upload_instructions_6')))])])])], 1)])], 1), _c(VLayout, {
    staticClass: "footer"
  }, [_c(VSpacer, {
    attrs: {
      "grow": ""
    }
  }), _c(VFlex, {
    attrs: {
      "shrink": ""
    }
  }, [_c(VBtn, {
    attrs: {
      "disabled": !_vm.isFormValid,
      "raised": "",
      "color": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.readFile(_vm.file);
      }
    }
  }, [_vm._v("next")])], 1)], 1)], 1), _c(VStepperContent, {
    attrs: {
      "step": "2"
    }
  }, [_vm.isFileEmpty ? _c('div', {
    staticClass: "row ma-2"
  }, [_vm._v(" " + _vm._s(_vm.$t('bulk_upload_no_update_in_file')) + " ")]) : _vm._e(), !_vm.isFileEmpty && ((_vm$recap = _vm.recap) === null || _vm$recap === void 0 ? void 0 : _vm$recap.elabelToCreateCount) <= _vm.totalInactiveCodesCount ? _c('div', [_c('div', {
    staticClass: "row ma-0"
  }, [_c(VCol, {
    staticClass: "overview-title font-weight-bold py-2 px-0",
    attrs: {
      "cols": "2",
      "shrink": ""
    }
  }, [_vm._v(_vm._s(_vm.$t('bulk_upload_overview')))]), _c(VCol, {
    attrs: {
      "grow": ""
    }
  }, [_c(VRow, {
    staticClass: "ma-0"
  }, [_c('div', {
    staticClass: "label mr-2"
  }, [_vm._v(_vm._s(_vm.$t('bulk_upload_category')) + ":")]), _vm.settings.selectedTemplate.key ? _c('div', {
    staticClass: "font-weight-bold"
  }, [_vm._v(_vm._s(_vm.$t("bulk_upload_".concat(_vm.settings.selectedTemplate.key))))]) : _c('div', {
    staticClass: "font-weight-bold"
  }, [_vm._v(_vm._s(_vm.$t("bulk_upload_".concat((_vm$settings$template = _vm.settings.templateElabel) === null || _vm$settings$template === void 0 ? void 0 : (_vm$settings$template2 = _vm$settings$template.data) === null || _vm$settings$template2 === void 0 ? void 0 : (_vm$settings$template3 = _vm$settings$template2.published) === null || _vm$settings$template3 === void 0 ? void 0 : _vm$settings$template3.type))))])]), _c(VRow, {
    staticClass: "ma-0"
  }, [_c('div', {
    staticClass: "label mr-2"
  }, [_vm._v(_vm._s(_vm.$t('bulk_upload_input_language')) + ":")]), _c('div', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.getLanguage(_vm.settings.defaultLanguage).description))])]), _c(VRow, {
    staticClass: "ma-0"
  }, [_c('div', {
    staticClass: "label mr-2"
  }, [_vm._v(_vm._s(_vm.$t('bulk_upload_published_languages')) + ":")]), _c('div', {
    staticClass: "font-weight-bold"
  }, _vm._l(_vm.settings.availableLanguages, function (lang, index) {
    return _c('span', {
      key: "available-lang-".concat(lang)
    }, [_c('span', {
      staticClass: "cut-margin"
    }, [_vm._v(_vm._s(_vm.getLanguage(lang).description))]), index < _vm.settings.availableLanguages.length - 1 ? _c('span', {
      staticClass: "comma"
    }, [_vm._v(", ")]) : _vm._e()]);
  }), 0)])], 1)], 1), _c('div', {
    staticClass: "delimiter"
  }), _c('div', {
    staticClass: "row ma-0"
  }, [_c(VCol, {
    staticClass: "overview-title font-weight-bold px-0 py-6",
    attrs: {
      "cols": "2",
      "shrink": ""
    }
  }, [_vm._v(_vm._s(_vm.$t('bulk_upload_file_summary')))]), _c(VCol, {
    attrs: {
      "grow": ""
    }
  }, [(_vm$recap2 = _vm.recap) !== null && _vm$recap2 !== void 0 && _vm$recap2.elabelToCreateCount ? _c(VRow, {
    staticClass: "ma-0 flex-nowrap"
  }, [_c(VCol, {
    staticClass: "file-summary-card new-elabel",
    attrs: {
      "cols": "5",
      "shrink": ""
    }
  }, [_c('div', {
    staticClass: "number font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t("bulk_upload_new_elabel_count", {
    count: (_vm$recap3 = _vm.recap) === null || _vm$recap3 === void 0 ? void 0 : _vm$recap3.elabelToCreateCount
  })) + " ")]), _c('div', [_vm._v(_vm._s(_vm.$t("bulk_upload_will_be_created")))])]), _c(VCol, {
    attrs: {
      "grow": ""
    }
  }, [_c(VSwitch, {
    staticClass: "mt-0",
    attrs: {
      "label": "Assign a code to the new e-labels"
    },
    model: {
      value: _vm.settings.assignCodeOnUpload,
      callback: function callback($$v) {
        _vm.$set(_vm.settings, "assignCodeOnUpload", $$v);
      },
      expression: "settings.assignCodeOnUpload"
    }
  }), _vm.settings.assignCodeOnUpload ? _c('div', [_c('div', [_vm._v(_vm._s(_vm.$t('bulk_upload_create_batch_name')) + ":")]), _c('div', [_vm._v(_vm._s(_vm.$t('bulk_upload_batch_name_hint')))]), _c(VForm, {
    ref: "batch-form",
    model: {
      value: _vm.batchFormValid,
      callback: function callback($$v) {
        _vm.batchFormValid = $$v;
      },
      expression: "batchFormValid"
    }
  }, [_c(VTextField, {
    staticClass: "mt-3 mb-6",
    attrs: {
      "label": _vm.$t('bulk_upload_batch_name'),
      "counter": "50",
      "rules": _vm.batchRules
    },
    model: {
      value: _vm.settings.uploadBatch,
      callback: function callback($$v) {
        _vm.$set(_vm.settings, "uploadBatch", $$v);
      },
      expression: "settings.uploadBatch"
    }
  })], 1)], 1) : _vm._e()], 1)], 1) : _vm._e(), (_vm$recap4 = _vm.recap) !== null && _vm$recap4 !== void 0 && _vm$recap4.elabelToCreateCount ? _c('div', {
    staticClass: "delimiter my-2"
  }) : _vm._e(), (_vm$recap5 = _vm.recap) !== null && _vm$recap5 !== void 0 && _vm$recap5.elabelToUpdateCount ? _c(VRow, {
    staticClass: "ma-0 flex-nowrap"
  }, [_c(VCol, {
    staticClass: "file-summary-card update-elabel",
    attrs: {
      "cols": "5",
      "shrink": ""
    }
  }, [_c('div', {
    staticClass: "number font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t("bulk_upload_update_elabel_count", {
    count: (_vm$recap6 = _vm.recap) === null || _vm$recap6 === void 0 ? void 0 : _vm$recap6.elabelToUpdateCount
  })) + " ")])]), _c(VCol, {
    attrs: {
      "grow": ""
    }
  }, [_c('div', [_vm._v(_vm._s(_vm.$t("bulk_upload_update_elabel_hint")))])])], 1) : _vm._e(), (_vm$recap7 = _vm.recap) !== null && _vm$recap7 !== void 0 && _vm$recap7.elabelToUpdateCount ? _c('div', {
    staticClass: "delimiter my-2"
  }) : _vm._e(), (_vm$recap8 = _vm.recap) !== null && _vm$recap8 !== void 0 && (_vm$recap8$productsNo = _vm$recap8.productsNotFound) !== null && _vm$recap8$productsNo !== void 0 && _vm$recap8$productsNo.length ? _c(VRow, {
    staticClass: "ma-0 flex-nowrap"
  }, [_c(VCol, {
    staticClass: "file-summary-card not-found",
    attrs: {
      "cols": "5",
      "shrink": ""
    }
  }, [_c('div', {
    staticClass: "number font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t("bulk_upload_not_found_elabel_count", {
    count: (_vm$recap9 = _vm.recap) === null || _vm$recap9 === void 0 ? void 0 : (_vm$recap9$productsNo = _vm$recap9.productsNotFound) === null || _vm$recap9$productsNo === void 0 ? void 0 : _vm$recap9$productsNo.length
  })) + " ")]), _c('div', [_vm._v(_vm._s(_vm.$t("bulk_upload_elabel_not_found")))])]), _c(VCol, {
    attrs: {
      "grow": ""
    }
  }, [_c('div', [_vm._v(_vm._s(_vm.$t("bulk_upload_elabel_not_found_description")))]), _vm._l((_vm$recap10 = _vm.recap) === null || _vm$recap10 === void 0 ? void 0 : _vm$recap10.productsNotFound, function (product, index) {
    var _vm$recap11;
    return _c('span', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: _vm.showAllNotFound == true || index < 20,
        expression: "showAllNotFound == true || index < 20"
      }],
      key: "product-not-found-".concat(index)
    }, [_c('span', {
      staticClass: "cut-margin font-weight-bold"
    }, [_vm._v(_vm._s(product))]), index < ((_vm$recap11 = _vm.recap) === null || _vm$recap11 === void 0 ? void 0 : _vm$recap11.productsNotFound.length) - 1 ? _c('span', {
      staticClass: "comma"
    }, [_vm._v(", ")]) : _vm._e()]);
  }), !_vm.showAllNotFound && ((_vm$recap12 = _vm.recap) === null || _vm$recap12 === void 0 ? void 0 : _vm$recap12.productsNotFound.length) > 20 ? _c('div', {
    on: {
      "click": function click($event) {
        _vm.showAllNotFound = true;
      }
    }
  }, [_vm._v(_vm._s(_vm.$t('view_all')))]) : _vm._e(), _vm.showAllNotFound ? _c('div', {
    on: {
      "click": function click($event) {
        _vm.showAllNotFound = false;
      }
    }
  }, [_vm._v(_vm._s(_vm.$t('view_less')))]) : _vm._e()], 2)], 1) : _vm._e()], 1)], 1)]) : _vm._e(), !_vm.isFileEmpty && ((_vm$recap13 = _vm.recap) === null || _vm$recap13 === void 0 ? void 0 : _vm$recap13.elabelToCreateCount) > _vm.totalInactiveCodesCount ? _c('div', {
    staticClass: "content mb-5"
  }, [_c('div', {
    staticClass: "important font-weight-bold"
  }, [_vm._v(_vm._s(_vm.$t('error')) + ":")]), _c('div', {
    staticClass: "infos mt-1 font-weight-medium"
  }, [_vm._v(_vm._s(_vm.$t('bulk_upload_quota_reached', {
    count: (_vm$recap14 = _vm.recap) === null || _vm$recap14 === void 0 ? void 0 : _vm$recap14.elabelToCreateCount,
    availableCount: _vm.totalInactiveCodesCount
  })))]), _c('div', {
    staticClass: "infos font-weight-medium"
  }, [_vm._v(_vm._s(_vm.$t('bulk_upload_quota_reached_contact_support')))])]) : _vm._e(), _c(VLayout, {
    staticClass: "footer"
  }, [_c(VFlex, {
    attrs: {
      "grow": ""
    }
  }, [_c(VBtn, {
    attrs: {
      "text": "",
      "color": "primary"
    },
    on: {
      "click": function click($event) {
        _vm.step = 1;
      }
    }
  }, [_vm._v("Back")])], 1), _c(VFlex, {
    attrs: {
      "shrink": ""
    }
  }, [_c(VBtn, {
    attrs: {
      "disabled": ((_vm$recap15 = _vm.recap) === null || _vm$recap15 === void 0 ? void 0 : _vm$recap15.elabelToCreateCount) > _vm.totalInactiveCodesCount || _vm.isFileEmpty || !((_vm$recap16 = _vm.recap) !== null && _vm$recap16 !== void 0 && _vm$recap16.elabelToCreateCount) && !((_vm$recap17 = _vm.recap) !== null && _vm$recap17 !== void 0 && _vm$recap17.elabelToUpdateCount),
      "raised": "",
      "color": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.startUpload();
      }
    }
  }, [_vm._v("next")])], 1)], 1)], 1), _c(VStepperContent, {
    staticClass: "custom-stepper",
    attrs: {
      "step": "3"
    }
  }, [_c('div', {
    staticClass: "loading"
  }, [_c('div', {
    attrs: {
      "align-center": "",
      "justify-center": "",
      "row": "",
      "fill-height": ""
    }
  }, [_c(VFlex, {
    staticClass: "text-center infos font-weight-bold",
    attrs: {
      "shrink": ""
    }
  }, [_vm.upload.loading && !_vm.upload.completed ? _c('span', [_vm._v(_vm._s(_vm.$t('uploading')) + "(" + _vm._s(_vm.uploadPercent) + "%)")]) : _vm._e(), _c('br'), _vm.upload.completed ? _c('span', [_vm._v(_vm._s(_vm.$t('upload_complete')))]) : _vm._e(), _c('br'), _c('div', {
    staticClass: "progress-ctn"
  }, [_c(VProgressLinear, {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.upload.loading,
      expression: "upload.loading"
    }],
    attrs: {
      "color": "#15BBF0",
      "rounded": "",
      "background-color": "#D9D9D9",
      "value": _vm.uploadPercent
    }
  })], 1), this.upload.throttling && _vm.upload.loading ? _c('div', {
    staticClass: "hint mt-3"
  }, [_vm._v(_vm._s(_vm.$t('bulk_upload_uploading_hint')))]) : _vm._e(), _vm.upload.completed ? _c('div', {
    staticClass: "upload-recap font-weight-light mt-3"
  }, [_vm.recap.elabelToCreateCount && _vm.settings.assignCodeOnUpload ? _c('span', [_vm._v(_vm._s(_vm.$t('bulk_upload_recap_created', {
    count: _vm.recap.elabelToCreateCount,
    batchName: _vm.settings.uploadBatch
  })))]) : _vm._e(), _c('br'), _vm.recap.elabelToCreateCount && !_vm.settings.assignCodeOnUpload ? _c('span', [_vm._v(_vm._s(_vm.$t('bulk_upload_recap_created_no_qr', {
    count: _vm.recap.elabelToCreateCount,
    batchName: _vm.settings.uploadBatch
  })))]) : _vm._e(), _c('br'), _vm.recap.elabelToUpdateCount ? _c('span', [_vm._v(_vm._s(_vm.$t('bulk_upload_recap_updated', {
    count: _vm.recap.elabelToUpdateCount
  })))]) : _vm._e(), _c('br')]) : _vm._e()])], 1)]), _c(VLayout, {
    staticClass: "footer",
    attrs: {
      "row": ""
    }
  }, [this.upload.completed && !this.settings.uploadBatch ? _c(VSpacer) : _vm._e(), this.upload.completed ? _c(VBtn, {
    attrs: {
      "text": this.settings.uploadBatch.length > 0,
      "raised": !this.settings.uploadBatch.length,
      "color": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.goToProducts();
      }
    }
  }, [_vm._v("back to products")]) : _vm._e(), this.upload.completed && !_vm.upload.errors.length && this.settings.uploadBatch ? _c(VSpacer) : _vm._e(), this.upload.completed && !_vm.upload.errors.length && this.settings.uploadBatch ? _c(VBtn, {
    attrs: {
      "raised": "",
      "color": "primary"
    },
    on: {
      "click": _vm.downloadBatch
    }
  }, [_vm._v("download this batch")]) : _vm._e()], 1)], 1)], 1)], 1)], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }