<template>
  <div class="dashboard">
    <div class="dashboard-ctn">
      <v-progress-linear
        v-show="!dashboardDataLoaded"
        indeterminate
        class="loader"
        color="primary"
      ></v-progress-linear>
      <v-row class="ma-0 pt-3 title-section" align="end">
        <div class="page-title mono ml-2 mr-3">Scan Report</div>
        <v-btn v-if="isAvailable('saas:dashboard:download')" class="ml-auto" @click="downloadScanData" text color="primary">
          <v-icon class="pr-2" color="primary">mdi-download</v-icon>
          Download
        </v-btn>
      </v-row>
      <v-row class="ma-3 mb-0">
        <search :campaign="dashboardCampaign" class="search"></search>
      </v-row>
      <v-row class="px-6 pt-6" align="center">
        <!-- <div v-if="!isAvailable('saas:dashboard:filters:timeframe')" class="description mono">Last 7 days</div> -->
        <!-- If allowed to use filters -->
        <div class="search-label mr-2 mono">Timeframe:</div>
        <v-select v-if="isAvailable('saas:dashboard:filters:timeframe')" solo v-model="timeframe" dense hide-details :items="this.timeframes" item-text="description" item-value="value" class="timeframe-select description mono flex-grow-0"></v-select>
        <div class="search-label ml-4 mr-2 mono" v-if="dashboardFilters.length">Filters:</div>
        <v-chip class="ml-2" close color="#f1f1f1" text-color="#0789b2" @click="editActiveFilter(filter)" @click:close="removeFilter(filter)" :key="`active-filters-${index}`" v-for="(filter, index) in dashboardFilters">
          {{filter.name}}: {{filter.value}}
        </v-chip>
      </v-row>

      <v-container v-if="dashboardDataLoaded" fluid>
        <v-row>
          <v-col cols="12" md="6" lg="4">
            <summary-card></summary-card>
          </v-col>
          <v-col cols="12" md="6" lg="4">
            <unique-count-card></unique-count-card>
          </v-col>
          <v-col cols="12" md="6" lg="4">
            <product-card></product-card>
          </v-col>
          <v-col cols="12">
            <scan-line-card></scan-line-card>
          </v-col>
          <v-col cols="12">
            <scan-by-time-card></scan-by-time-card>
          </v-col>
          <v-col cols="12" lg="8">
            <scan-heatmap-card></scan-heatmap-card>
          </v-col>
          <v-col cols="12" md="4">
            <sku-donut-card></sku-donut-card>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>

</template>

<script>

import { mapGetters, mapMutations } from 'vuex'
import Api from '@/api'
import axios from 'axios'

import SummaryCard from '@/components/dashboard/Summary'
import UniqueCountCard from '@/components/dashboard/UniqueCount'
import ProductCard from '@/components/dashboard/Products'
import ScanLineCard from '@/components/dashboard/ScanLine'
import ScanByTimeCard from '@/components/dashboard/ScanByTime'
import SkuDonutCard from '@/components/dashboard/SkuDonut'
import HeatmapCard from '@/components/dashboard/ScanHeatmap'
import Search from '@/components/dashboard/Search'

export default {
  name: 'dashboard',
  components: {
    'summary-card': SummaryCard,
    'unique-count-card': UniqueCountCard,
    'product-card': ProductCard,
    'scan-line-card': ScanLineCard,
    'scan-by-time-card': ScanByTimeCard,
    'sku-donut-card': SkuDonutCard,
    'scan-heatmap-card': HeatmapCard,
    Search
  },
  data () {
    return {
      timeframes: [{
        description: 'Last week',
        value: '-1w'
      }, {
        description: 'Last Month',
        value: '-1m'
      }]
    }
  },
  watch: {
    async selectedCampaign (campaign) {
      if (campaign) {
        this.setDashboardCampaign(campaign)
        await this.$store.dispatch('loadSummary', { campaign: this.dashboardCampaign })
        await this.$store.dispatch('loadScans', { campaign: this.dashboardCampaign, count: this.scanSummary.meta.scans.total })
        this.$store.dispatch('loadScmFields', campaign.id)
      }
      // this.setDashboardCampaign(this.campaigns[0])
    }
  },
  computed: {
    ...mapGetters(['campaigns', 'scanSummary', 'dashboardCampaign', 'dashboardDataLoaded', 'isAvailable', 'scantrustAuthToken', 'dashboardFilters', 'selectedCampaign']),

    timeframe: {
      get () {
        return this.$store.getters.timeframe
      },
      set (newVal) {
        this.$store.dispatch('changeDashboardTimeframe', newVal)
      }
    }
  },
  methods: {
    ...mapMutations({
      setDashboardCampaign: 'SET_DASHBOARD_CAMPAIGN',
      setDashboardLoadStatus: 'SET_DASHBOARD_LOAD_STATUS',
      removeDashboardFilter: 'REMOVE_DASHBOARD_FILTER'
    }),

    editActiveFilter (filter) {
      this.$refs.scanSearch.selectTag(filter, filter.searchValue || '')
    },

    removeFilter (filter) {
      this.removeDashboardFilter(filter)
      this.cancellableLoad()
    },

    cancellableLoad () {
      if (this.cancelToken && this.cancelToken.cancel) {
        this.cancelToken.cancel('Cancelling request')
      }

      this.cancelToken = axios.CancelToken.source()
      this.$store.dispatch('loadScans', { campaign: this.dashboardCampaign, count: this.scanSummary.meta.scans.total, cancelToken: this.cancelToken })
      this.$store.dispatch('loadSummary', { campaign: this.dashboardCampaign, cancelToken: this.cancelToken })
    },

    downloadScanData () {
      const url = `${Api.st_config.API_BASE_URL}dashboard/scans/csv/?campaign=${this.dashboardCampaign.id}&token=${this.scantrustAuthToken}&timeframe=${this.timeframe}`
      window.location.assign(url)
    }
  },
  async created () {
    if (this.campaigns.length) {
      this.setDashboardCampaign(this.campaigns[0])
    } else {
      await this.$store.dispatch('loadCampaigns')
      this.setDashboardCampaign(this.campaigns[0])
    }

    await this.$store.dispatch('loadSummary', { campaign: this.dashboardCampaign })
    await this.$store.dispatch('loadScans', { campaign: this.dashboardCampaign, count: this.scanSummary.meta.scans.total })
    this.setDashboardLoadStatus(true)
  }
}

</script>

<style lang="sass" scoped>

@import "@/variables.sass"

.loader
  position: absolute
  top: 0px
  left: 0
  right: 0

.dashboard
  height: calc(100vh - 63px)
  overflow: auto

.dashboard-ctn
  margin-left: auto
  margin-right: auto
  width: 100%
  @include lg
    max-width: 80%
  @include xl
    max-width: 70%

.title-section
  .page-title
    font-size: 1.7rem
    color: $title-grey

  .v-icon
    color: $title-grey
    padding-bottom: 3px

  .description
    padding-bottom: 3px
    padding-left: 10px
    font-size: 1rem
    color: $title-grey

.search
  width: 100%

.timeframe-select
  width: 160px

.search-label
  font-size: 16px
  color: #222222
  opacity: 0.5
  text-transform: uppercase

.v-card
  height: 200px

</style>
