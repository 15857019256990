<template>
  <v-app-bar absolute color="#ffffff">
    <v-row align="stretch" justify="space-between">

      <v-img
          :src="require('../assets/st-logo.svg')"
          class="ml-5"
          max-width="170px"
          contain
        />

      <v-row v-if="currentPlan" class="justify-end align-center ma-0">
        <nav>
          <ul class="nav-list font-weight-medium">
            <li @click="sendNavigationEvent('dashboard')" class="nav-item" :class="{'active': this.active == 'DASHBOARD'}">
              <router-link to="/">
                Dashboard
              </router-link>
            </li>
            <li @click="sendNavigationEvent('products')" class="nav-item" :class="{'active': this.active == 'PRODUCTS'}">
              <router-link to="/products">
                Products
              </router-link>
            </li>
            <li @click="sendNavigationEvent('landing-page')" class="nav-item" :class="{'active': this.active == 'LANDING-PAGE'}">
              <router-link to="/landing-page">
                Landing Page
              </router-link>
            </li>
            <li @click="sendNavigationEvent('upgrade')" class="nav-item" :class="{'active': this.active == 'UPGRADE'}">
              <router-link to="/upgrade">
                Upgrade
              </router-link>
            </li>
            <!-- <li class="nav-item" :class="{'active': this.active == 'REDIRECTION'}">
              <router-link to="/redirections">
                Redirection
              </router-link>
            </li> -->
          </ul>
        </nav>
        <v-menu full-width offset-y>
          <template v-slot:activator="{ on, attrs }">
            <div class="account-icon pr-2" :class="{ 'pr-5': currentPlan.id == 'epac-connect-premium' }">
              <v-btn v-bind="attrs" v-on="on" icon>
                <v-icon>mdi-account-circle</v-icon>
              </v-btn>
              <div class="plan-pill">
                <v-img v-if="currentPlan.id == 'epac-connect'" :src="require('../assets/free.svg')" />
                <v-img v-if="currentPlan.id == 'epac-connect-plus'" :src="require('../assets/plus.svg')" />
                <v-img v-if="currentPlan.id == 'epac-connect-premium'" :src="require('../assets/premium.svg')" />
              </div>
            </div>
          </template>
          <v-list>
            <v-list-item class="menu-link">
              <router-link to="/upgrade">
                <span class="font-weight-bold mr-5 navigation-menu-label uppercase">Current Plan:</span><br>
                <v-row class="ma-0" align="center" justify="center">
                  <v-col class="flex-grow-0 pa-0 mr-3">
                    <v-img class="pill" v-if="currentPlan.id == 'epac-connect'" :src="require('../assets/free.svg')" />
                    <v-img class="pill" v-if="currentPlan.id == 'epac-connect-plus'" :src="require('../assets/plus.svg')" />
                    <v-img class="pill" v-if="currentPlan.id == 'epac-connect-premium'" :src="require('../assets/premium.svg')" />
                  </v-col>
                  <v-col class="pa-0">
                    {{planName}}
                  </v-col>
                </v-row>
              </router-link>
            </v-list-item>
            <v-list-item @click="logout">
              <v-icon class="mr-3">mdi-logout</v-icon>
              Logout
            </v-list-item>
          </v-list>
        </v-menu>
      </v-row>

    </v-row>
  </v-app-bar>
</template>

<script>

import Permissions from '@/const/permissions'
import { mapGetters } from 'vuex'
import amplitude from 'amplitude-js'

export default {
  name: 'Navigation',
  props: ['active'],
  computed: {

    ...mapGetters(['currentPlan']),

    planName () {
      if (Permissions[this.currentPlan.id]) {
        return Permissions[this.currentPlan.id].name
      } else if (this.currentPlan && this.currentPlan.name) {
        return this.currentPlan.name
      }
      return ''
    }
  },
  methods: {
    sendNavigationEvent (tabName) {
      amplitude.getInstance().logEvent(`${tabName} open`)
      parent.postMessage({ type: 'navigation', data: tabName }, '*')
    },

    logout () {
      parent.postMessage({ type: 'logout' }, '*')
    }
  }
}

</script>

<style lang="sass" scoped>

@import "@/variables.sass"

.nav-list
  list-style: none
  margin: 0
  padding: 0
  .nav-item
    line-height: 57px
    margin: 0px 16px
    display: inline-block
    position: relative
    a
      text-decoration: none
      color: $black
    .nav-indicator
      bottom: 0
      position: absolute
      height: 6px
      background: white
    &.active
      border-bottom: 6px solid $primary

.account-icon
  position: relative
  .plan-pill
    position: absolute
    top: 2px
    right: 0px

.menu-link
  a
    text-decoration: none
    color: $black !important

.navigation-menu-label
  font-size: 12px

</style>
