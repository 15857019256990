import Vue from 'vue'
import Vuex from 'vuex'

import auth from './modules/auth'
import codes from './modules/codes'
import campaigns from './modules/campaigns'
import dashboard from './modules/dashboard'
import elabel from './modules/elabel'
import landingPages from './modules/landing-pages'
import products from './modules/products'
import qr from './modules/qr'
import redirection from './modules/redirection'
import snackbar from './modules/snackbar'
import workorders from './modules/workorders'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    auth,
    codes,
    dashboard,
    elabel,
    campaigns,
    landingPages,
    products,
    qr,
    redirection,
    snackbar,
    workorders
  }
})
